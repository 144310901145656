<template>
  <div>
    <h3>Change Password </h3>
    <p>Basic Info, Like Your Name And Address, That You Use On Nio Platform.</p>

    <form @submit.prevent="submit">
      <!-- current password -->
      <b-form-group
          id="name"
          label="Current password"
          label-for="password">
        <b-form-input
            id="password"
            v-model="password.old_password"
            type="password"
            placeholder="Current password"
            required
        ></b-form-input>
      </b-form-group>

      <!-- new password -->
      <b-form-group
          id="name"
          label="New password"
          label-for="new_password">
        <b-form-input
            id="new_password"
            v-model="password.new_password"
            type="password"
            placeholder="New password"
            required
        ></b-form-input>
      </b-form-group>

      <!-- confirm password -->
      <b-form-group
          id="name"
          label="Confirm password "
          label-for="confirm_password">
        <b-form-input
            id="confirm_password"
            v-model="password.confirm_password"
            type="password"
            placeholder="Confirm password "
            required
            @input="$delete(errors, 'confirm')"
        ></b-form-input>

        <p class="text-danger" v-if="errors.hasOwnProperty('confirm')">{{errors['confirm']}}</p>
      </b-form-group>

      <p class="text-danger mb-0" v-if="typeof loginError == 'string'">{{loginError}}</p>
      <p class="text-danger mb-0" v-else v-for="(err, index) in loginError" :key="index">{{err}}</p>

      <!-- submit button -->
      <div class="text-center">
        <button class="rounded-button" type="submit">
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: "ChangePassword",
  data() {
    return {
      password: {},
      errors: {},
      loginError: []
    }
  },
  methods: {
    submit() {
      this.loginError = [];
      if (this.password.new_password != this.password.confirm_password) {
        Vue.set(this.errors, 'confirm', 'The passwords should be matched')
      } else {
        this.$API.profile.changePassword(this.password)
        .then(response => {
          this.$toastr.s(response.message);
          this.password = {}
        })
        .catch(error => {
          this.loginError = error.data.message
        })
      }
    }
  }
}
</script>

<style scoped>
button {
  margin-top: 20px;
}
</style>