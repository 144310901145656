import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import api from './api/index'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueToastr from "vue-toastr";
import store from './store/index'
import './assets/style/index.css'
import BlockUI from 'vue-blockui'
import vmodal from 'vue-js-modal'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'daterangepicker/daterangepicker'
import 'daterangepicker/daterangepicker.css'
import InputColorPicker from "vue-native-color-picker";

Vue.config.ignoredElements = ['field','block','category','xml','mutation','value','sep']


Vue.use(BlockUI)
Vue.use(BootstrapVue)
Vue.prototype.$API = api;
Vue.config.productionTip = false;
Vue.use(vmodal);
Vue.use(VueQuillEditor);
Vue.use(InputColorPicker);

Vue.use(VueToastr, {
  defaultTimeout: 3000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  // defaultType: "error",
  defaultPosition: "toast-bottom-center",
  defaultCloseOnHover: false,
  // defaultClassNames: ["animated", "zoomInUp"]
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
