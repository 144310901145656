<template>
  <div class="page-shadow faq">
    <!-- loading -->
    <BlockUI message="Loading Question ..." v-if="loading"></BlockUI>
    <!-- end loading -->

    <!-- FAQ Header -->
    <div class="Faq-header">
      <div>
        <p>All FAQ</p>
      </div>

      <!-- add faq button -->
      <b-button class="rounded-button add-faq" @click="modalShow = true">
        <img src="@/assets/icons/add.svg">
        Add FAQ
      </b-button>

      <!--  add faq modal -->
      <b-modal hide-footer hide-header v-model="modalShow" @hidden="modalClosed">
        <FaqModal @reGetData="modalShow = false" :question="toEdit"></FaqModal>
      </b-modal>

    </div>

    <div class="event-types">
      <div class="faq-block" @click.prevent="$set(form, 'type', 'all')" :class="{'active': form.type == 'all'}">
        <img src="@/assets/images/national.png" alt="National" class="mb-2">
        <p>Global Organizers</p>
      </div>
      <div class="faq-block" @click.prevent="$set(form, 'type', 'international')"
           :class="{'active': form.type == 'international'}">
        <img src="@/assets/images/international.png" alt="International" class="mb-2">
        <p>International Events</p>
      </div>
      <div class="faq-block" @click.prevent="$set(form, 'type', 'national')"
           :class="{'active': form.type == 'national'}">
        <img src="@/assets/images/national.png" alt="National" class="mb-2">
        <p>National Organizers</p>
      </div>
    </div>

    <!-- Chose category -->
    <choose-category @get_cat_id="fitch_cat_id"></choose-category>

    <!-- FAQ Question -->
    <div class="faq-question">
      <div class="accordion faq-body" role="tablist">
        <template v-if="faq_data.length > 0">

          <b-card no-body class="faq-card mb-1" v-for="(item,index) in faq_data" :key="index">
            <b-card-header header-tag="header" class="faq-card-header p-1" role="tab">
              <!-- question header -->
              <b-button class="faq-button-q" block v-b-toggle="'accordion-'+ index" variant="light">
                {{ item.question }}
                <!-- Collapse Icons -->
                <span class="when-closed">
                  <img src="@/assets/icons/add-b.svg">
                </span>
                <span class="when-opened">
                  <img src="@/assets/icons/close-b.svg">
              </span>
              </b-button>
              <!-- question actions -->
              <div class="d-flex align-items-center">
                <b-button class="question-btn" @click="modalShow = true; toEdit = item">
                  <svg>
                    <use xlink:href="@/assets/images/sprite.svg#edit"></use>
                  </svg>
                </b-button>

                <a href="javascript:void(0)" v-b-modal="'delete-question'"
                   @click.prevent.stop="toDelete = item">
                  <svg class="delete-question">
                    <use xlink:href="@/assets/images/sprite.svg#trash"></use>
                  </svg>
                </a>
              </div>
            </b-card-header>
            <b-collapse :id="'accordion-'+index" visible accordion="my-accordion" role="tabpanel">
              <b-card-body class="faq-card-body">
                <b-card-text>{{ item.answer }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </template>
        <p class="text-center" v-else>There are no Questions to show</p>

      </div>
    </div>
    <b-modal id="delete-question" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.name" @cancelDelete="toDelete = null" @confirmDelete="deleteQuestion"></Delete>
    </b-modal>
  </div>
</template>

<script>
import ChooseCategory from "@/components/FAQ/ChooseCategory";
import FaqModal from "@/components/FAQ/FAQModal";
import Delete from "@/components/Modals/Delete";

export default {
  name: 'FAQ',
  components: {FaqModal, ChooseCategory, Delete},
  data() {
    return {
      loading: true,
      faq_data: [],
      show: false,
      faq: [
        {
          question: 'test ?',
          text: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry\n richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor\n          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon\n          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla\n          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore\n          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher\n          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic\n          synth nesciunt you probably haven\'t heard of them accusamus labore VHS.',
        }
      ],
      form: {
        type: 'all'
      },
      modalShow: false,
      toEdit: null,
      toDelete: null
    }
  },
  watch: {
    'form': {
      deep: true,
      handler: 'show_FAQ'
    },
  },
  mounted() {
    this.show_FAQ();
  },
  methods: {
    show_FAQ() {
      this.loading = true;
      this.$API.faq.show_FAQ(this.form)
          .then(response => {
            this.loading = false;
            this.faq_data = response.data.data;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    modalClosed() {
      this.show_FAQ();
      this.toEdit = null
    },
    fitch_cat_id(e) {
      this.form.category_id = e;
      this.show_FAQ()
    },
    deleteQuestion() {
      this.loading = true
      this.$API.faq.delete_FAQ(this.toDelete.id)
          .then(response => {
            this.loading = false;
            this.$toastr.s(response.message);
            this.toDelete = null;
            this.show_FAQ()
          }).catch(error => {
        this.loading = false;
        this.$toastr.e(error.message)
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>