import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get all organizers
     * @api {GET} admin/originsers
     * @param {number} page
     * @returns {Promise} represents list of organizers
     */
    getOrganizers(page, form) {
        const options = {
            method: 'GET',
            url: API + 'admin/originsers',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {'page': page, ...form}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                    Vuex.commit('setPages', response.data.data.meta.lastPage)
                    Vuex.commit('setCurrentPage', response.data.data.meta.currentPage)

                }).catch(error => {
                reject(error)
            })
        })
    },


    /**
     * GET organizer from web api with no pagination
     * @api {GET} web/originsers/
     */
    get_orgnaizer_noPagination() {
        const options = {
            method: 'GET',
            url: API + 'web/orginsers',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Get single organizer details
     * @api {GET} admin/originsers/:organizer_id
     * @param {number} organizerID
     * @returns {Promise}
     */
    getOrganizerDetails(organizerID) {
        const options = {
            method: 'GET',
            url: API + 'admin/originsers/' + organizerID,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                reject(error)
            })
        })
    },


    /**
     * Change organizer status
     * @api {POST} admin/originsers/:status
     * @param status
     * @param organizerID
     * @returns {Promise}
     */
    changeOrganizerStatus(status, organizerID) {
        const options = {
            method: 'POST',
            url: API + 'admin/originsers/' + status,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {orginser_id: organizerID}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    /**
     * Update link and notes in organiser profile
     * @api {POST} admin/originsers/updatedetails
     * @param {object} data
     * @returns {Promise}
     */
    updateLinkNotes(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/originsers/updatedetails',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {...data}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    /**
     * Delete an organizer
     * @param {number} organiserId
     * @returns {Promise}
     */
    deleteOrganiser(organiserId) {
        const options = {
            method: 'Delete',
            url: API + 'admin/originsers/' + organiserId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    getCountries() {
        const options = {
            method: 'GET',
            url: 'https://wro-v.com/backend/api/web/getcountries',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);

                }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Update organizer email and password
     * @api {POST} admin/originsers/updatedate
     */
    updateCredentials(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/originsers/updatedate',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {...data}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
}