import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get all organizers payments
     * @api {GET} admin/originsers
     * @returns {Promise} represents list of organizers
     * @param param use as filter by ( paid, user_id, orginzer_id)
     */
    getOrganizerPayment(page, form) {
        const options = {
            method: 'GET',
            url: API + 'web/getPayments',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {page, ...form }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data.payments);
                    Vuex.commit('setPages', response.data.data.meta.lastPage)
                    Vuex.commit('setCurrentPage', response.data.data.meta.currentPage)
                }).catch(error => {
                reject(error)
            })
        })
    },


}