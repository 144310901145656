<template>
  <div class="page-shadow training-control">
    <BlockUI message="Submitting ..." v-if="loading"></BlockUI>

    <form @submit.prevent="submit">
      <!-- image -->
      <div class="form-group">
        <label>Upload Image</label>
        <div class="display-image">
          <b-form-file @change="imageUploaded" ref="myFiles" plain></b-form-file>
          <img :src="url" alt="">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
          </svg>
        </div>
        <p class="text-danger" v-if="errors.hasOwnProperty('image')">{{ errors['image'][0] }}</p>
      </div>

      <div class="row">

        <div class="col-lg-6">
          <!-- title -->
          <b-card class="my-card">
            <b-card-text>
              <div class="text-h6">English Content</div>
            </b-card-text>
            <b-card-text>
              <b-form-group id="title" label="Title" label-for="title">
                <b-form-input required id="title" v-model="form.title" type="text" @input="delete errors.title"
                  placeholder="Enter Title"></b-form-input>
                <p class="text-danger" v-if="errors.hasOwnProperty('title')">{{ errors['title'][0] }}</p>
              </b-form-group>
              <b-form-group id="long_description" label="Long Description " label-for="long_description">
                <b-form-textarea required id="long_description" min="0" v-model="form.description" type="number"
                  @input="delete errors.description" placeholder="Enter Long Description "></b-form-textarea>
                <p class="text-danger" v-if="errors.hasOwnProperty('description')">{{ errors['description'][0] }}</p>
              </b-form-group>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-lg-6">
          <b-card class="my-card">
            <b-card-text>
              <div class="text-h6">االعربية Content</div>
            </b-card-text>
            <b-card-text>
              <b-form-group id="title_ar" label="Title (االعربية)" label-for="title_ar">
                <b-form-input required id="title_ar" v-model="form.title_ar" type="text" @input="delete errors.title_ar"
                  placeholder="Enter Title  (االعربية)"></b-form-input>
                <p class="text-danger" v-if="errors.hasOwnProperty('title_ar')">{{ errors['title_ar'][0] }}</p>
              </b-form-group>
              <!-- description -->
              <b-form-group id="long_description_ar" label="Long Description (االعربية)" label-for="long_description_ar">
                <b-form-textarea required id="long_description_ar" min="0" v-model="form.description_ar" type="number"
                  @input="delete errors.description_ar"
                  placeholder="Enter Long Description  (االعربية)"></b-form-textarea>
                <p class="text-danger" v-if="errors.hasOwnProperty('description_ar')">{{ errors['description_ar'][0] }}
                </p>
              </b-form-group>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <!-- upload -->
      <div class="upload">
        <div class="row">

          <!-- video -->
          <div class="col-lg-4">
            <b-form-checkbox id="video" v-model="form.type" name="upload" value="video" unchecked-value="">
              Watch Video
            </b-form-checkbox>

            <input type="text" class="form-control mt-2" v-model="form.youtube" placeholder="Video Link"
              v-if="form.type == 'video'">
            <p class="text-danger" v-if="errors.hasOwnProperty('video')">{{ errors.video[0] }}</p>
          </div>

          <!-- pdf -->
          <div class="col-lg-4">
            <b-form-checkbox id="PDF" v-model="form.type" name="upload" value="pdf" unchecked-value="">
              Read PDF
            </b-form-checkbox>

            <div class="video-file" v-if="form.type == 'pdf'">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#upload"></use>
              </svg>
              <template v-if="pdfFileName">{{ pdfFileName }}</template>
              <template v-else>Read PDF</template>
              <b-form-file accept="application/pdf" v-model="form.pdf" @change="fileUploaded"></b-form-file>
              <p class="text-danger" v-if="errors.hasOwnProperty('pdf')">{{ errors.pdf[0] }}</p>
            </div>
          </div>

          <!-- scorum -->
          <div class="col-lg-4">
            <b-form-checkbox id="scorum" v-model="form.type" name="upload" value="scorum" unchecked-value="">
              Watch scorm
            </b-form-checkbox>
            <!--            <input type="text" class="form-control mt-2" v-model="form.scorum" placeholder="scorum Link" v-if="form.type == 'scorum'">-->
            <div class="video-file" v-if="form.type == 'scorum'">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#upload"></use>
              </svg>
              <template v-if="pdfFileName">{{ scorumName }}</template>
              <template v-else>Upload scorm</template>
              <b-form-file
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                v-model="form.scorum" @change="scorumUploaded"></b-form-file>
              <p class="text-danger" v-if="errors.hasOwnProperty('scorum')">{{ errors.scorum[0] }}</p>
            </div>
          </div>

          <p class="col-12 text-danger" v-if="errors.hasOwnProperty('type')">{{ errors.type[0] }}</p>
        </div>
      </div>

      <!-- link -->
      <b-form-group id="link" label="Link" label-for="link">
        <b-form-input required id="link" v-model="form.link" type="text" @input="delete errors.link"
          placeholder="Enter Link"></b-form-input>
        <p class="text-danger" v-if="errors.hasOwnProperty('link')">{{ errors['link'][0] }}</p>
      </b-form-group>

      <b-form-group id="category" label="Category" label-for="Category">
        <b-form-select v-model="form.category" :options="categories"></b-form-select>
        <p class="text-danger" v-if="errors.hasOwnProperty('category')">{{ errors['category'][0] }}</p>
      </b-form-group>

      <!-- submit -->
      <div class="text-center">
        <button class="rounded-button" type="submit">
          <template v-if="form.id">Update</template>
          <template v-else>Done</template>
        </button>
      </div>

    </form>

  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "Edit",
  data() {
    return {
      categories: ['3D Painting', 'Controllers', 'Sensing And Control Systems'],
      url: null,
      form: {},
      errors: {},
      pdfFileName: null,
      scorumName: null,
      loading: false
    }
  },
  watch: {
    'form.type'(newVal, oldVal) {
      if (oldVal == 'video') {
        delete this.form['youtube'];
      }
      delete this.form[oldVal];
      delete this.errors[oldVal];
      delete this.errors.type
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.$API.trainingSupport.trainingDetails(this.$route.params.id)
        .then(response => {
          this.form.title = response.title;
          this.form.description = response.description;
          this.form.title_ar = response.title_ar;
          this.form.description_ar = response.description_ar;
          this.form.category = response.category;
          if (response.youtube) this.form.youtube = response.youtube;
          Vue.set(this.form, 'type', response.type)
          this.form.link = response.link;
          if (response.image) {
            this.url = response.image
          }
        })
    }
  },
  methods: {
    imageUploaded(e) {
      let file = e.target.files[0];
      Vue.set(this.form, 'image', file)
      this.url = URL.createObjectURL(file);
      delete this.errors.image
    },

    fileUploaded(e) {
      this.pdfFileName = e.target.files[0].name
    },

    scorumUploaded(e) {
      this.scorumName = e.target.files[0].name
    },

    submit() {
      this.loading = true;
      let formData = new FormData();
      Object.keys(this.form).map(key => {
        formData.append(key, this.form[key])
      })

      if (this.$route.params.id) {
        formData.append('_method', 'PUT');
        this.$API.trainingSupport.updateTraining(formData, this.$route.params.id)
          .then(response => {
            this.$toastr.s(response);
            this.loading = false;
          }).catch(errors => {
            this.loading = false;
            this.errors = errors
          })
      } else {
        this.$API.trainingSupport.createTraining(formData)
          .then(response => {
            this.$toastr.s(response);
            this.loading = false;
          }).catch(errors => {
            this.loading = false;
            this.errors = errors
          })
      }
    }
  }
}
</script>

<style scoped></style>