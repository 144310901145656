<template>
  <div class="page-shadow">
    <BlockUI message="Loading Plan Data..." v-if="loading"></BlockUI>
    <form @submit.prevent="submit">
      <!-- image -->
      <div class="form-group">
        <label>Upload Image</label>
        <div class="display-image">
          <b-form-file @change="imageUploaded" ref="myFiles" v-model="image" plain></b-form-file>
          <img :src="url" alt="">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
          </svg>
        </div>
      </div>

      <!-- title -->
      <b-form-group
          id="title"
          label="Title"
          label-for="title">
        <b-form-input
            required
            id="title"
            v-model="form.title"
            type="text" @input="delete errors.title"
            placeholder="Enter Title"
        ></b-form-input>
        <p class="text-danger" v-if="errors.hasOwnProperty('title')">{{ errors['title'][0] }}</p>
      </b-form-group>

      <!-- price -->
      <b-form-group
          id="price"
          label="Price"
          label-for="price">
        <b-form-input
            required
            id="price"
            min="0"
            v-model="form.price"
            type="number" @input="delete errors.price"
            placeholder="Enter Price"
        ></b-form-input>
        <p class="text-danger" v-if="errors.hasOwnProperty('price')">{{ errors['price'][0] }}</p>
      </b-form-group>

      <!-- advantages -->
      <div class="form-group">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <label>Advantages</label>
          <button class="wro-btn primary" @click.prevent="form.advantages.push('')">
            Add
          </button>
        </div>


        <div v-for="(adv, index) in form.advantages" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <input type="text"
                   class="mb-2 form-control"
                   @input="delete errors['advantages.' + index]"
                   v-model="form.advantages[index]">
            <a href="javascript:void(0)" @click.prevent="$delete(form.advantages, index)" class="pl-3">
              <svg class="delete-icon">
                <use xlink:href="@/assets/images/sprite.svg#delete"></use>
              </svg>
            </a>
          </div>
          <p class="text-danger" v-if="errors.hasOwnProperty('advantages.' + index)">{{errors['advantages.' + index][0]}}</p>
        </div>
      </div>
      <!-- submit -->
      <div class="text-center">
        <button class="rounded-button" type="submit">
          <template v-if="form.id">Update</template>
          <template v-else>Save</template>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "EditPlan",
  data() {
    return {
      url: null,
      form: {
        advantages: []
      },
      image: null,
      loading: true,
      errors: {}
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.$API.plans.getAllPlans(this.$route.params.id)
          .then(response => {
            this.url = response[0].image;
            Vue.set(this.form, 'id', response[0].id);
            Vue.set(this.form, 'title', response[0].title);
            Vue.set(this.form, 'price', response[0].price);
            Vue.set(this.form, 'advantages', response[0].advantages);
            this.loading = false
          }).catch(() => {
        this.loading = false
      })
    } else this.loading = false
  },
  methods: {
    imageUploaded(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },

    submit() {
      this.loading = true;
      let formData = new FormData();
      if (this.image) formData.append('image', this.image);
      formData.append('title', this.form.title);
      formData.append('price', this.form.price);
      this.form.advantages.map((adv, index) => {
        formData.append(`advantages[${index}]`, adv);
      })

      if (this.form.id) {
        formData.append('_method', 'put');
        this.$API.plans.updatePlan(this.form.id, formData)
            .then(response => {
              this.loading = false;
              this.$toastr.s(response.message)
            }).catch(errors => {
          if (typeof errors == 'string') this.$toastr.e(errors)
          else this.errors = errors
          this.loading = false;
        })
      } else {
        this.$API.plans.addPlan(formData)
            .then(response => {
              this.loading = false;
              this.$toastr.s(response.message);
              this.form = response.data
            }).catch(errors => {
          if (typeof errors == 'string') this.$toastr.e(errors)
          else this.errors = errors
          this.loading = false;
        })
      }
    }
  }
}
</script>

<style scoped>
.page-shadow {
  padding: 16px 80px 42px 62px;
}

.display-image {
  border-radius: 5px;
  padding: 0;
  min-height: 164px;
  height: auto;
}

.display-image img {
  border-radius: 5px;
}

img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .page-shadow {
    padding: 10px 25px;
  }
}
</style>