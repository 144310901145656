import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {
    /**
     * Get list of trainings
     * @api {GET} api/admin/training
     * @returns {Promise} represents list of trainings
     */
    getTraining() {
        const options = {
            method: 'GET',
            url: API + 'admin/training',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Get list of trainings
     * @api {GET} api/admin/training/:id
     * @returns {Promise} represents list of trainings
     */
    trainingDetails(id) {
        const options = {
            method: 'GET',
            url: API + 'admin/training/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Create training
     * @api {POST} admin/training
     * @returns {Promise} represents list of trainings
     */
    createTraining(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/training',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data

        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Create training
     * @api {POST} admin/training
     * @returns {Promise} represents list of trainings
     */
    updateTraining(data, id) {
        const options = {
            method: 'POST',
            url: API + 'admin/training/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data

        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Delete training
     * @api {POST} admin/training/:id
     * @returns {Promise} represents list of trainings
     */
    deleteTraining(id) {
        const options = {
            method: 'DELETE',
            url: API + 'admin/training/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
}