import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get logged in user profile data
     * @api {GET} admin/getprofile
     * @returns {Promise}
     */
    getProfile() {
        const options = {
            method: 'GET',
            url: API + 'admin/getprofile',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
            })
        })
    },

    /**
     * update user profile
     * @api {PUT} admin/updateprofile
     * @param {formData} data
     * @returns {Promise} represents the saved data
     */
    updateProfile(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/updateprofile',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                    Vuex.commit('setUser', response.data.data);
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                }).catch(error => {
                    reject(error)
            })
        })
    },

    /**
     * Change logged in user password
     * @api {{POST}} admin/change_password
     * @param {object} data
     * @returns {Promise}
     */
    changePassword(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/change_password',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response)
            })
        })
    }
}