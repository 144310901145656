import axios from "../axios";
import {API} from '../../env'
import Vuex from '../../store'

export default {

    /**
     * Get All National Event
     * @api {GET} admin/orginzerevents
     * @param {object} filter
     * @param {number} page
     * @returns,
     */
    get_events(filter, page){

        const options = {
            method: 'GET',
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            url: API + 'admin/orginzerevents',
            params: {...filter, page: page},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                    Vuex.commit('setPages', response.data.data.meta.lastPage)
                    Vuex.commit('setCurrentPage', page)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Update Event
     * @api {POST} admin/orginzerevents/:id
     * @param {number} id,
     * @param {object} data
     * @returns,
     */
    update_event(id, data){

        const options = {
            method: 'POST',
            url: API + `admin/orginzerevents/${id}`,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params:id,
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Show Event
     * @api {GET} admin/orginzerevents/:id
     * @param id,
     * @returns,
     */
    show_event(id){

        const options = {
            method: 'GET',
            url: API + `admin/orginzerevents/${id}`,
            params:id,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Delete Event
     * @api {DELETE} admin/events
     * @param,
     * @returns,
     */

    delete_event(id) {
        const options = {
            method: 'DELETE',
            url: API + 'admin/orginzerevents/'+ id,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params:id,
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Change event status
     * @api {POST} web/updateActiveEvent
     * @param {number} event_id
     * @param {number} status
     * @return {Promise} represents the status of the action
     */

    changeStatus(event_id, status) {
        const options = {
            method: 'POST',
            url: API + 'web/updateActiveEvent',
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params: {event_id: event_id, active: status},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

}