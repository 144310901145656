<template>
  <div class="d-flex flex-wrap justify-content-between page-header align-items-center">
    <h1 class="page-title">{{ $route.name }}</h1>
    <div class="right-part">

      <!-- user data -->
      <div class="profile-data">
        <div class="username more">
          <a href="javascript:void(0)" class="d-block"
             @click.prevent="show == 'userList'? show = null: show = 'userList'">
            <svg class="">
              <use xlink:href="@/assets/images/sprite.svg#user-droplist"></use>
            </svg>
            {{ user.name }}
          </a>
          <ul class="more-actions" :class="{'show': show == 'userList'}">
            <li>
              <router-link to="/profile" exact>Profile</router-link>
            </li>
            <li>
              <a href="javascript:void(0)" @click.prevent="logout">Logout</a>
            </li>
          </ul>
        </div>
        <div class="user-avatar">
          <img v-if="user.image" :src="user.image" alt="Avatar">
        </div>
      </div>

      <!-- notifications -->
      <div class="notification-wrapper">
        <div class="more">
          <a href="javascript:void(0)" @click.prevent="show == 'notifications'? show = null: show = 'notifications'">
            <svg class="icon-26">
              <use xlink:href="@/assets/images/sprite.svg#notification"></use>
            </svg>
          </a>
          <ul class="more-actions notifications" :class="{'show': show == 'notifications'}">
            <li>
              <span>Notification 1</span>
            </li>
            <li>
              <span>Notification 2</span>
            </li>
            <li class="border-top">
              <router-link to="/" class="mb-0 blue-text">All Notifications</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- search form -->
      <form @submit.prevent="search" class="search-form">
        <div class="search-input">
          <input type="text" placeholder="Search">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#search"></use>
          </svg>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import $ from 'jquery'

export default {
  name: "ThePageHeader",
  data() {
    return {
      show: null
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    $('body').click(() => {
      this.show = null
    })
    $('.more').click((e) => {
      e.preventDefault()
      e.stopPropagation()
    })
  },
  methods: {
    search() {

    },
    logout() {
      this.$API.auth.logout()
          .then(response => {
            this.$router.push('/login')
            this.$toastr.s(response)
          })
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>