<template>
  <div class="settings">
    <BlockUI message="Loading Settings..." v-if="loading"></BlockUI>

    <form class="row" @submit.prevent="submit" v-else>
      <!-- Facebook -->
      <div class="col-xl-6">
        <b-form-group
            id="facebook"
            label="Facebook *"
            label-for="facebook">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.facebook"
                v-model="formData.facebook"
                type="text"
                placeholder="Facebook"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.facebook" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/facebook.png">
          </div>
        </b-form-group>
      </div>

      <!-- Twitter -->
      <div class="col-xl-6">
        <b-form-group
            id="Twitter"
            label="Twitter *"
            label-for="Twitter">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.twitter"
                v-model="formData.twitter"
                type="text"
                placeholder="Twitter"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.twitter" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/twitter.png">
          </div>
        </b-form-group>
      </div>

      <!-- LinkedIn -->
      <div class="col-xl-6">
        <b-form-group
            id="LinkedIn"
            label="LinkedIn *"
            label-for="LinkedIn">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.linkedin"
                v-model="formData.linkedin"
                type="text"
                placeholder="LinkedIn"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.linkedin" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/linkedin.png">
          </div>
        </b-form-group>
      </div>

      <!-- instagram -->
      <div class="col-xl-6">
        <b-form-group
            id="Instagram"
            label="Instagram *"
            label-for="Instagram">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.instagram"
                v-model="formData.instagram"
                type="text"
                placeholder="Instagram"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.instagram" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/instagram.png">
          </div>

        </b-form-group>
      </div>

      <!-- telegram -->
      <div class="col-xl-12">
        <b-form-group
            id="telegram"
            label="Telegram"
            label-for="Telegram">
          <b-form-input
              @input="delete errors.telegram"
              v-model="formData.telegram"
              type="text"
              placeholder="Telegram"
          ></b-form-input>
          <span class="text-danger" v-for="error in errors.telegram" :key="error.id">{{ error }}</span>
        </b-form-group>
      </div>

      <!-- Email  -->
      <div class="col-xl-6">
        <b-form-group
            id="Email "
            label="Email  *"
            label-for="Email ">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.email"
                v-model="formData.email"
                type="text"
                placeholder="Email "
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.email" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/gmail.png">
          </div>

        </b-form-group>
      </div>

      <!-- Phone  -->
      <div class="col-xl-6">
        <b-form-group
            id="Phone "
            label="Phone  *"
            label-for="Phone ">
          <div class="input-holder">
            <b-form-input
                v-model="formData.phone "
                type="text"
                placeholder="Phone"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.phone" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/phone.png">
          </div>

        </b-form-group>
      </div>

      <!-- Address -->
      <div class="col-xl-12">
        <b-form-group
            id="Address"
            label="Address *"
            label-for="Address">
          <div class="input-holder">
            <b-form-input
                @input="delete errors.address"
                v-model="formData.address"
                type="text"
                placeholder="Address"
                required
            ></b-form-input>
            <span class="error" v-for="error in errors.address" :key="error.id">{{ error }}</span>
            <img src="@/assets/images/social/surface1.png">
          </div>

        </b-form-group>
      </div>

      <!-- title  -->
      <div class="col-xl-12">
        <b-form-group
            id="Title "
            label="Title *"
            label-for="Title ">
          <b-form-input
              @input="delete errors.main_title"
              v-model="formData.main_title"
              type="text"
              placeholder="Title"
              required
          ></b-form-input>
          <span class="error" v-for="error in errors.main_title" :key="error.id">{{ error }}</span>
        </b-form-group>
      </div>

      <!-- description -->
      <div class="col-xl-12">
        <b-form-group
            id="Description"
            label="Description *"
            label-for="Description">
          <b-form-textarea
              @input="delete errors.main_description"
              v-model="formData.main_description"
              type="text" rows="4"
              placeholder="Description"
              required
          ></b-form-textarea>
          <span class="error" v-for="error in errors.main_description" :key="error.id">{{ error }}</span>

        </b-form-group>
      </div>

      <!-- about us -->
      <div class="col-12">
        <b-form-group
            id="about_us"
            label="About Us "
            label-for="about_us">
          <quill-editor
              :content="formData.about"
              @change="onEditorChange($event)">
          </quill-editor>
          <p class="text-danger" v-if="errors.hasOwnProperty('about')">{{ errors['about'][0] }}</p>
        </b-form-group>
      </div>

      <!-- submit button -->
      <div class="col-12 text-center">
        <button class="rounded-button" type="submit">
          Save
        </button>
      </div>
    </form>
    <span class="success" v-if="successMessage">{{ successMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  data: () => ({
    loading: true,
    formData: {},
    successMessage: '',
    errors: [],
  }),
  methods: {
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.formData.about = html
    },
    submit() {
      this.successMessage = '';
      this.$API.settings.updateSettings(this.formData)
          .then(response => {
            this.successMessage = response.message;
          })
          .catch(error => {
            this.errors = error.message;
          });
    },
  },
  mounted() {
    this.$API.settings.showSettings()
        .then(response => {
          this.loading = false;
          this.formData = response.data;
          this.formData['_method'] = 'PUT';
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        })
  }
}
</script>

<style scoped>

</style>