<template>
  <div class="page-shadow organisers">
    <div class="page-with-table">
      <div class="table-header">
        <h3>Mange National Organizer</h3>
      </div>

      <BlockUI message="Loading Organizers..." v-if="!organizers"></BlockUI>

      <!-- filter form -->
      <form class="row filter-form">
        <!-- country -->
        <div class="col-md-4">
          <b-form-group
              id="country"
              label="Country"
              label-for="country">
            <b-form-select v-model="form.country_id">
              <option value="undefined" disabled>Select Country</option>
              <option v-for="country in countries" :value="country.id" :key="country.id">
                {{ country.name }}
              </option>
            </b-form-select>


          </b-form-group>
        </div>

        <!-- filter with status -->
        <div class="col-md-4">
          <b-form-group
              id="status"
              label="Status"
              label-for="status">
            <b-form-select v-model="form.active">
              <option value="undefined" disabled>Select Status</option>
              <option v-for="el in status" :value="el.key" :key="el.key">{{ el.name }}</option>
            </b-form-select>

          </b-form-group>
        </div>

        <!-- reset form -->
        <div class="col-md-4 align-self-end">
          <div class="form-group">
            <button class="wro-btn primary" @click.prevent="form = {}">Reset</button>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table wro-table">
          <thead>
          <tr>
            <td>Company's name</td>
            <td>Image</td>
            <td>Country</td>
            <td>Email</td>
            <td>Telephone</td>
            <td>Actions</td>
          </tr>
          </thead>
          <tbody>
          <template v-if="organizers && organizers.length > 0">
            <tr v-for="organiser in organizers" :key="organiser.id">
              <td>
                <div class="d-flex align-items-center">
                  {{ organiser.name }}
                  <div class="inactive-label" v-if="organiser.active == 2"></div>
                </div>
              </td>
              <td>
                <div class="logo-wrapper">
                  <img v-if="organiser.logo" :src="organiser.logo" alt="logo">
                </div>
              </td>
              <td>{{ organiser.country }}</td>
              <td>{{ organiser.email }}</td>
              <td>{{ organiser.phone }}</td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="more" @click.prevent.stop="">
                    <a href="javascript:void(0)"
                       @click.prevent="showMore == organiser.id ? showMore = null: showMore = organiser.id">
                      <svg class="small">
                        <use xlink:href="@/assets/images/sprite.svg#more"></use>
                      </svg>
                    </a>
                    <ul class="more-actions" :class="{'show': showMore == organiser.id}">
                      <li>
                        <router-link :to="'/organizers/details/' + organiser.id">View
                        </router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0)"
                           @click.prevent="editCredentials = organiser; credentialsModal= true">
                          Edit credentials
                        </a>
                      </li>
                      <li>
                        <a href="#" @click.prevent="toDelete = organiser" v-b-modal="'delete-organizer'">Delete</a>
                      </li>
                    </ul>
                  </div>

                  <template v-if="organiser.active == 0">
                    <button class="success wro-btn" @click.prevent="acceptOrganizer(organiser)">
                      Accept
                    </button>
                    <button class="danger wro-btn" @click.prevent="refuseOrganizer(organiser)">
                      Refuse
                    </button>
                  </template>

                  <template v-else>
                    <b-form-checkbox :checked="organiser.active == 1? true: false" switch
                                     @change="statusChanged(organiser)">
                      <template v-if="organiser.active == 1">Active</template>
                      <template v-if="organiser.active == 2">Inactive</template>

                    </b-form-checkbox>
                  </template>
                </div>

              </td>
            </tr>
          </template>
          <tr v-else class="text-center">
            <td colspan="6">There is no data to show</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="organizers && organizers.length > 0" @changePage="getAllOrganizers"></Pagination>

    </div>

    <b-modal hide-footer hide-header v-model="credentialsModal" @hidden="modalClosed">
      <edit-credentials :credentials="editCredentials"
                        @updatedSuccessfully="credentialsModal = false"></edit-credentials>
    </b-modal>


    <b-modal id="delete-organizer" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.name" @cancelDelete="toDelete = null" @confirmDelete="deleteOrganiser"></Delete>
    </b-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import EditCredentials from "./EditCredentials";
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  name: "Index",
  components: {Delete, Pagination, EditCredentials},
  data() {
    return {
      organizers: null,
      showMore: null,
      countries: [],
      status: [
          {
        key: 0,
        name: 'New'
      }, {
        key: 1,
        name: 'Active'
      }, {
        key: 2,
        name: 'Inactive'
      }],
      form: {},
      editCredentials: null,
      credentialsModal: false,
      toDelete: null
    }
  },
  watch: {
    'form': {
      deep: true,
      handler: 'filter'
    }
  },
  mounted() {
    this.getAllOrganizers(1);
    this.$API.organizers.getCountries()
        .then(response => {
          this.countries = response
        });

    $('body').click(() => {
      this.showMore = null
    })
  },
  methods: {
    filter() {
      this.getAllOrganizers(1)
    },

    /**
     * Get all organizers
     * @param {number} page
     */
    getAllOrganizers(page) {
      this.organizers = null

      this.$API.organizers.getOrganizers(page, this.form)
          .then(response => {
            this.organizers = response.data
          })
    },

    /**
     * Method invoked when organiser status is changed
     * @param {object} organiser
     */
    statusChanged(organiser) {
      this.organizers = null
      if (organiser.active == 1) {
        this.refuseOrganizer(organiser)
      } else {
        this.acceptOrganizer(organiser)
      }
    },

    /**
     * Method invoked when we click on accept button
     */
    acceptOrganizer(organiser) {
      this.$API.organizers.changeOrganizerStatus('active', organiser.id)
          .then(response => {
            this.$toastr.s(response.message)
            this.getAllOrganizers()
          })
          .catch(error => {
            if (typeof error.message == 'string') {
              this.$toastr.e(error.message)
              this.getAllOrganizers()
            }
          })
    },

    /**
     * Method invoked when we click on refuse button
     */
    refuseOrganizer(organiser) {
      this.$API.organizers.changeOrganizerStatus('disactive', organiser.id)
          .then(response => {
            this.$toastr.s(response.message);
            this.getAllOrganizers()
          })
          .catch(error => {
            if (typeof error.message == 'string') {
              this.$toastr.e(error.message)
              this.getAllOrganizers()
            }
          })
    },

    /**
     * Delete an existing organizer
     * Invoked when we click on delete link
     */
    deleteOrganiser() {
      this.organizers = null
      this.$API.organizers.deleteOrganiser(this.toDelete.id)
          .then(response => {
            this.$toastr.s(response);
            this.toDelete = null;
            this.getAllOrganizers(1);
          }).catch(err => {
        this.$toastr.e(err)
        this.getAllOrganizers(1);
      })
    },

    /**
     * Method invoked when we close update name and password modal
     */
    modalClosed() {
      this.editCredentials = null;
      this.getAllOrganizers()
    }
  }
}
</script>

<style scoped>
.inactive-label {
  margin-left: 15px;
}
</style>