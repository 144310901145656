<template>
  <div class="page-shadow organizer-profile">

    <BlockUI message="Loading Ambassador Details..." v-if="!data"></BlockUI>
    <div v-else class="organizer-profile-holder">
      <div class="row profile-data-wrapper">
        <div class="col-lg-6">
          <div class="profile-data">
            <div class="d-flex">
              <div class="profile-image">
                <img v-if="data.logo" :src="data.logo" alt="">
              </div>
              <div>
                <h3>{{ data.name }}</h3>
                <p class="building">
                  <svg>
                    <use xlink:href="@/assets/images/sprite.svg#building"></use>
                  </svg>
                  {{ data.company_name }}
                </p>
                <b-form-checkbox switch :checked="status"
                                 @change="statusChanged()">Active</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="contact-info">
            <p class="contact-header">Contact Person:</p>

            <p class="username">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#user"></use>
              </svg>
              {{ data.name }}
            </p>

            <p class="email">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#email"></use>
              </svg>
              {{ data.email }}
            </p>

            <p class="phone">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#phone"></use>
              </svg>
              {{ data.phone }}
            </p>
          </div>
        </div>
      </div>

      <div class="details">
        <p>
          {{ data.notes }}
        </p>
      </div>

      <!-- Add admin note -->
      <form @submit.prevent="submit" class="position-relative">
        <block-element v-if="loading == 'form'"></block-element>

        <b-form-group
            class="form-input"
            label="Add Note *"
            label-for="admin_note">

          <b-form-textarea
              class="from-textarea"
              placeholder="Enter Note"
              v-model="form.admin_notes"
          ></b-form-textarea>

        </b-form-group>
        <div class="input-link-holder">
          <b-form-group
              class="form-input"
              label="Add Link *"
              label-for="admin_note">
            <div class="wrapper">
              <a href="javascript:void(0)" class="input-icon" @click.prevent="enableEdit = true">
                <span>Edit</span>
                <img src="@/assets/icons/edit.png">
              </a>
              <b-form-input
                  :disabled="enableEdit? false: true"
                  :class="{'opacity-5': !enableEdit}"
                  id="user_link"
                  type="text"
                  placeholder="Enter Link"
                  v-model="form.link"
              ></b-form-input>
            </div>
            <p class="text-muted">Click edit to enable text input</p>
          </b-form-group>

          <div class="input-icon-outline" @click="copyLink">
            <img src="@/assets/icons/copy.png">
            <span>Copy</span>
          </div>

        </div>
        <!-- submit button -->
        <div class="text-center">
          <button class="rounded-button" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BlockElement from "@/components/BlockElement";
export default {
  name: "ambassadorProfile",
  components: {BlockElement},
  data() {
    return {
      status: true,
      data: null,
      enableEdit: false,
      form: {
        ambassador_id: this.$route.params.id,
        link: null,
        admin_notes: null
      },
      loading: null
    }
  },
  mounted() {
    this.$API.ambassadors.getAmbassadorDetails(this.$route.params.id)
        .then(response => {
          this.data = response;
          this.status = response.active == 1 ? true: false
          this.form.link = response.link;
          this.form.admin_notes = response.admin_notes;
        })
  },
  methods: {
    copyLink() {
      let user_link = document.getElementById('user_link');
      user_link.select();
      user_link.setSelectionRange(0, 99999); /* For mobile devices */

      document.execCommand("copy");

      this.$toastr.s('Link is copied!')

    },

    statusChanged() {
      this.status = !this.status
      if (this.status) {
        this.$API.ambassadors.changeAmbassadorStatus('active', this.data.id)
            .then(response => {
              this.$toastr.s(response.message)
            })
      } else {
        this.$API.ambassadors.changeAmbassadorStatus('disactive', this.data.id)
            .then(response => {
              this.$toastr.s(response.message)
            })
      }
    },

    /**
     * Method invoked when we submit update notes and link
     */
    submit() {
      this.loading = 'form'
      this.$API.ambassadors.updateLinkNotes(this.form)
          .then(response => {
            this.loading = null;
            this.$toastr.s(response.message)
          }).catch(errors => {
        this.loading = null;
        if (typeof errors.message == 'string') {
              this.$toastr.e(errors.message)
            }
      })
    }
  }
}
</script>

<style scoped>
.opacity-5 {
  opacity: 0.5 !important;
}
</style>