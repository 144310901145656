<template>
  <div id="app">
    <div class="d-flex" v-if="token">
      <TheSidebar></TheSidebar>
      <div class="page-wrapper">
        <ThePageHeader></ThePageHeader>
        <ul class="breadcrumb" v-if="breadcrumb && breadcrumb.length > 0">
          <li v-for="(page, index) in breadcrumb" :key="index">
            <router-link :to="page.link" v-if="index != breadcrumb.length - 1">{{page.text}}</router-link>
            <span v-else>{{page.text}}</span>
            <svg class="angle-right"  v-if="index != breadcrumb.length - 1">
              <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
            </svg>
          </li>
        </ul>

        <div class="page-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import TheSidebar from "@/components/layouts/TheSidebar";
import ThePageHeader from "@/components/layouts/ThePageHeader";
import {mapState} from 'vuex'
import $ from 'jquery'


export default {
  name: 'App',
  components: {
    TheSidebar,
    ThePageHeader
  },
  data() {
    return {
      breadcrumb: null
    }
  },
  computed: {
    ...mapState(['token'])
  },
  mounted() {
    this.breadcrumb = this.$route.meta.breadcrumb
    if ($(window).width() <= 992) {
      $('body').addClass('sidebar-xs')
    }
  },
  watch: {
    '$route'() {
      this.breadcrumb = this.$route.meta.breadcrumb
    }
  }
}
</script>

<style>
#app {
  min-height: 100vh;
}
.page-wrapper {
  padding: 30px 50px;
}
</style>
