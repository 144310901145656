import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index'
import Login from "@/pages/Login";
import Home from "@/pages/Home";
import Profile from '@/pages/profile/Index';
import Organizers from '../pages/Organizers/Index';
import OrganizerProfile from '../pages/Organizers/organizerProfile'
import Ambassadors from '../pages/Ambassadors/index'
import AmbassadorsProfile from '../pages/Ambassadors/AmbassadorsProfile'
import Competitions from '../pages/competitions/Index'
import EditCompetition from '../pages/competitions/EditCompetition'
import Plans from '../pages/plans/Index'
import EditPlan from '../pages/plans/EditPlan'
import ContactUS from '../pages/contactUs/Index'
import ContactUSDetails from '../pages/contactUs/Details'
import Settings from '../pages/Settings'
import FAQ from '../pages/FAQ/FAQ'
import Blockly from '../pages/blockly2/Blockly'
import Event from '../pages/event/Event'
import EditEvent from "@/pages/event/Global/EditEvent";
import EditNationalEvent from "@/pages/event/National/EditNationalEvent";
import TrainingSupport from "@/pages/training&support/Index"
import EditTraining from "@/pages/training&support/Edit"
import Payment from "@/pages/payment"

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
    store.dispatch('setUser')
        .then(user => {
            if (user) {
                next();
            } else {
                next('/login')
            }
        })
}

const guestGuard = (to, from, next) => {
    store.dispatch('setUser')
        .then(user => {
            if (!user) {
                next()
            } else {
                next('/')
            }
        })
}


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: guestGuard
    },
    {
        path: '/game',
        name: 'Blockly',
        component: Blockly,
        beforeEnter: guestGuard
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Home,
        beforeEnter: authGuard
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: authGuard
    },
    {
        path: '/organizers',
        name: 'Organizers',
        component: Organizers,
        beforeEnter: authGuard,
    },
    {
        path: '/organizers/details/:id',
        name: 'Mange N - O',
        component: OrganizerProfile,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/organizers',
                    text: 'Mange National Organizer'
                }, {
                    link: '#',
                    text: 'Details'
                }
            ]
        }
    },
    {
        path: '/ambassadors',
        name: 'Ambassadors ',
        component: Ambassadors,
        beforeEnter: authGuard,
    },
    {
        path: '/ambassadors/details/:id',
        name: 'Mange A - O',
        component: AmbassadorsProfile,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/ambassadors',
                    text: 'Mange Ambassadors'
                }, {
                    link: '#',
                    text: 'Details'
                }
            ]
        }
    },
    {
        path: '/competitions',
        name: 'Competition',
        component: Competitions,
        beforeEnter: authGuard,
    },
    {
        path: '/competitions/edit/:id',
        name: 'Edit Competition',
        component: EditCompetition,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/competitions',
                    text: 'Competitions'
                }, {
                    link: '#',
                    text: 'Edit'
                }
            ]
        }
    },
    {
        path: '/competitions/add',
        name: 'Add Competition',
        component: EditCompetition,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/competitions',
                    text: 'Competitions'
                }, {
                    link: '#',
                    text: 'Add'
                }
            ]
        }
    },
    {
        path: '/plans',
        name: 'Plans',
        component: Plans,
        beforeEnter: authGuard,
    },
    {
        path: '/plans/edit/:id',
        name: 'Edit Plan',
        component: EditPlan,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/plans',
                    text: 'Plans'
                }, {
                    link: '#',
                    text: 'Edit'
                }
            ]
        }
    },
    {
        path: '/plans/add',
        name: 'Add Plan',
        component: EditPlan,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/plans',
                    text: 'Plans'
                }, {
                    link: '#',
                    text: 'Add'
                }
            ]
        }
    },
    {
        path: '/contact-us',
        name: 'Contact Us',
        component: ContactUS,
        beforeEnter: authGuard
    },
    {
        path: '/contact-us/details/:id',
        name: 'Contact-Us',
        component: ContactUSDetails,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/contact-us',
                    text: 'Contact Us'
                }, {
                    link: '#',
                    text: 'Details'
                }
            ]
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: authGuard,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
        beforeEnter: authGuard,
    },
    {
        path: '/events',
        name: 'Events',
        component: Event,
        beforeEnter: authGuard,
    },
    {
        path: '/events/edit/:id',
        name: 'Edit Events',
        component: EditEvent,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/events',
                    text: 'Events'
                },
                {
                    link: '#',
                    text: 'Edit'
                }
            ]
        }

    },
    {
        path: '/events/edit-National-Events/:id',
        name: 'Edit National Events',
        component: EditNationalEvent,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/events',
                    text: 'Events'
                },
                {
                    link: '#',
                    text: 'Edit'
                }
            ]
        }

    },
    {
        path: '/training-support',
        name: 'Training And Support',
        component: TrainingSupport,
        beforeEnter: authGuard,
    },
    {
        path: '/training-support/add',
        name: 'Training And Support Add',
        component: EditTraining,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/training-support',
                    text: 'Training And Support'
                }, {
                    link: '#',
                    text: 'Add'
                }
            ]
        }
    },
    {
        path: '/training-support/edit/:id',
        name: 'Training And Support Edit',
        component: EditTraining,
        beforeEnter: authGuard,
        meta: {
            breadcrumb: [
                {
                    link: '/training-support',
                    text: 'Training And Support'
                }, {
                    link: '#',
                    text: 'Edit'
                }
            ]
        }
    },
    // {
    //     path: '/training-support/edit/:id',
    //     name: 'Training And Support',
    //     component: EditTraining,
    //     beforeEnter: authGuard,
    //     meta: {
    //         breadcrumb: [
    //             {
    //                 link: '/training-support',
    //                 text: 'Training And Support'
    //             }, {
    //                 link: '#',
    //                 text: 'Edit'
    //             }
    //         ]
    //     }
    // },
    {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        beforeEnter: authGuard,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
