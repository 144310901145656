<template>
    <div class="form-model-container">
        <div class="text-header">
            <p>Update Credentials</p>
        </div>

        <BlockUI message="Updating Data ..." v-if="loading"></BlockUI>
        <b-form autocomplete="off" @submit.prevent="submit">
            <!-- email -->
            <b-form-group
                    class="mb-15"
                    id="input-group-1"
                    label="Email"
                    label-for="email">
                <b-form-input
                        onfocus="this.removeAttribute('readonly');" readonly
                        autocomplete="off"
                        id="email"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter Email"
                        required
                ></b-form-input>
                <p class="text-danger error-text" v-if="errors.hasOwnProperty('email')">{{errors['email'][0]}}</p>
            </b-form-group>

            <!-- password -->
            <b-form-group
                    id="input-group-2"
                    label="Password"
                    label-for="password">
                <b-form-input
                        autocomplete="off"
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Enter Password"
                        required
                ></b-form-input>
            </b-form-group>

            <!-- submit button -->
            <div class="submit-button text-center">
                <button class="rounded-button" type="submit">
                    Update
                </button>
            </div>
        </b-form>
    </div>
</template>

<script>
    export default {
        name: "Credentials",
        props: ['credentials'],
        data() {
            return {
                form: {
                  ambassador_id: this.credentials.id,
                  email: this.credentials.email
                },
                errors: {},
                loading: false
            }
        },
        methods: {
            submit() {
                this.loading = true;
                this.$API.ambassadors.updateCredentials(this.form)
                .then(() => {
                    this.$toastr.s('Updated Successfully');
                    this.loading = false;
                    this.$emit('updatedSuccessfully')
                }).catch(error => {
                    this.$toastr.e(error);
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>

</style>