<template>
  <div>

    <!-- Add Modal Button -->
    <div class="mb-30">
      <button to="/plans/add" class="rounded-button add-plan" @click="openModel">
        <img src="@/assets/images/add-button.png" alt="Add Plan">
        Add Event
      </button>
    </div>
    <!-- End Add Modal Button -->

    <!--  Modal Form -->
    <b-modal hide-footer hide-header v-model="modalShow">

      <!-- loading -->
      <BlockUI message="Sending Data..." v-if="loading"></BlockUI>
      <!-- loading -->

      <!-- Close Form -->
      <img src="@/assets/icons/cancel.png" @click="modalShow = false">
      <!-- End close form -->

      <div class="form-model-container">
        <div class="text-header">
          <p >Add Event</p>
        </div>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="p-0 pr-2 col-8">
              <!-- Event Name -->
              <b-form-group
                  class=""
                  id="name"
                  label="Event Name"
                  label-for="question">
                <b-form-input
                    v-model="form.title"
                    id="question"
                    type="text"
                    placeholder="Event Name"
                    required
                ></b-form-input>
                <div class="error" v-if="error.title">
                  <p class="m-1 text-danger" v-for="item in error.title" :key="item.id">{{item}}</p>
                </div>
              </b-form-group>

              <!-- Event Details -->
              <b-form-group
                  class=""
                  id="name"
                  label="Details"
                  label-for="question">
                <b-form-textarea
                    v-model="form.details"
                    id="question"
                    type="text"
                    placeholder="Add Details"
                    required
                ></b-form-textarea>
                <div class="error" v-if="error.details">
                  <p class="m-1 text-danger" v-for="item in error.details" :key="item.id">{{item}}</p>
                </div>
              </b-form-group>
            </div>

            <div class="p-0 col-4">
              <!-- image -->
              <div class="form-group">
                <label>Upload Image</label>
                <div class="display-image">
                  <b-form-file @change="imageUploaded" ref="myFiles" v-model="form.image" plain></b-form-file>
                  <img v-if="url" :src="url" alt="">
                  <svg>
                    <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
                  </svg>
                </div>
                <div class="error" v-if="error.image">
                  <p class="m-1 text-danger" v-for="item in error.image" :key="item.id">{{item}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- time -->
          <div class="event-data">
            <div>
              from
            </div>
            <div class="input-data">
              <b-form-datepicker v-model="form.start_date" id="datepicker-invalid" :state="false" class="mb-2"></b-form-datepicker>
              <div class="error" v-if="error.start_date">
                <p class="m-1 text-danger" v-for="item in error.start_date" :key="item.id">{{item}}</p>
              </div>
            </div>
            <div>
              to
            </div>
            <div class="input-data">
              <b-form-datepicker v-model="form.end_date"  :state="false" class="mb-2"></b-form-datepicker>
              <div class="error" v-if="error.end_date">
                <p class="m-1 text-danger" v-for="item in error.end_date" :key="item.id">{{item}}</p>
              </div>
            </div>
          </div>

          <!-- submit button -->
          <div class="text-center">
            <button class="rounded-button" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </b-modal>
    <!-- End Modal Form -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: '',
        details: '',
        image: null,
        location:null,
        start_date: null,
        end_date: null,
      },
      error:[],
      url:null,
      modalShow: false,
      loading:false,
    }
  },
  methods:{
    openModel(){
      this.modalShow = !this.modalShow;
    },
    imageUploaded(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    submit(){
      let form_data = new FormData();
      form_data.append('title',this.form.title);
      form_data.append('details',this.form.details);
      form_data.append('image',this.form.image);
      form_data.append('start_date',this.form.start_date);
      form_data.append('end_date',this.form.end_date);

      this.$API.globalEvent.add_event(form_data)
      .then(response => {
        this.$toastr.s(response.message);
        this.$API.globalEvent.get_events()
            .then(response => {
              this.$store.commit('set_global_event',response.data);
            })
            .catch(() => {

            });

      })
      .catch(error => {
        this.error = error.data.message;
      });
    }
  }
}
</script>

<style scoped>
.event-data {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
}

.input-data{
  width: 44%;
}

.b-form-datepicker{
  height: 50px!important;
  align-items: center!important;
}

.display-image{
  height: 253px;
}

.display-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-control-file{
  height: 100% !important;
}


</style>
