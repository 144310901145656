<template>
  <div class="plans">
    <div class="mb-30">
      <router-link to="/plans/add" class="rounded-button add-plan">
        <img src="@/assets/images/add-button.png" alt="Add Plan">
        Add Plan
      </router-link>
    </div>

    <BlockUI message="Loading Plans..." v-if="loading"></BlockUI>

    <div class="row" v-if="plans && plans.length > 0">
      <div class="col-md-6 col-lg-4" v-for="plan in plans" :key="plan.id">
        <div class="wro-card sm-padding-bottom">
          <div class="wro-card-content sm-padding-bottom">
            <div class="d-flex">
              <h3>{{ plan.title }}</h3>
              <div class="more" @click.prevent.stop="">
                <a href="javascript:void(0)"
                   @click.prevent="showMore == plan.id ? showMore = null: showMore = plan.id">
                  <svg class="small dark">
                    <use xlink:href="@/assets/images/sprite.svg#more"></use>
                  </svg>
                </a>
                <ul class="more-actions" :class="{'show': showMore == plan.id}">
                  <li>
                    <router-link :to="'/plans/edit/' + plan.id">Edit</router-link>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click.prevent="toDelete = plan" v-b-modal="'delete-plan'">Delete</a>
                  </li>
                </ul>
              </div>
            </div>



            <div class="text-center">
              <img :src="plan.image" alt="">
            </div>
            <ul>
              <li v-for="(advantage, index) in plan.advantages" :key="index">{{ advantage }}</li>
            </ul>
          </div>

          <div class="edit-button">
            <router-link :to="'/plans/edit/' + plan.id">
              <img src="@/assets/images/edit.png" alt="Edit">
              Edit Plan
            </router-link>
          </div>
        </div>
      </div>

    </div>
    <h5 class="text-center" v-else>There are no plans to show</h5>

    <b-modal id="delete-plan" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.title" @cancelDelete="toDelete = null" @confirmDelete="deletePlan"></Delete>
    </b-modal>
  </div>
</template>

<script>
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  name: "PlansIndex",
  components: {Delete},
  data() {
    return {
      plans: null,
      loading: true,
      showMore: null,
      toDelete: null
    }
  },
  mounted() {
    this.fetchPlans();
    $('body').click(() => {
      this.showMore = null
    })
  },
  methods: {
    fetchPlans() {
      this.$API.plans.getAllPlans()
          .then(response => {
            this.loading = false;
            this.plans = response
          }).catch(() => {
        this.loading = false
      })
    },

    deletePlan() {
      this.loading = true;
      this.$API.plans.deletePlan(this.toDelete.id)
          .then(response => {
            this.fetchPlans();
            this.toDelete = null;
            this.$toastr.s(response)
          })
          .catch(error => {
            this.loading = false
            this.$toastr.e(error)
          });
    }
  }
}
</script>

<style scoped>
.add-plan {
  color: #ffffff;
  display: inline-flex;
  align-items: center;
}
</style>