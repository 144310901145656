<template>
  <div>
    <div class="wro-modal">
      <p class="mb-4 text-danger">Are you sure you want to delete {{ toDelete }} ?</p>
      <div class="text-center">
        <button class="mx-2 wro-btn danger" @click.prevent="$emit('confirmDelete')">Yes</button>
        <button class="mx-2 wro-btn primary" @click="$emit('cancelDelete')">No</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Delete",
  props: ['toDelete']
}
</script>

<style scoped>
button {
  width: 100px;
}
</style>