import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('user'),
        pages: null,
        currentPage: null,
        available_cat:[],
        global_event:[],
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        },
        setUser(state, payload) {
            state.user = payload
        },
        removeUser(state) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            state.token = null;
            state.user.token = null;
            state.user = null
        },
        setPages(state, payload) {
            state.pages = payload
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload
        },
        set_available_cat(state, payload){
            state.available_cat = payload;
        },
        set_global_event(state,payload){
            state.global_event = payload;
        }
},
    getters: {
        token(state) {

            return state.token
        }
    },
    actions: {
        setUser({state, commit}) {
            let user = localStorage.getItem('user')
            if (!user && state.token == null) {
                return
            }
            user = JSON.parse(user)
            commit('setUser', user);
            return state.user
        }
    },

})
