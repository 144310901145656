<template>
  <ul class="pagination">
    <li class="" :class="{'disabled' : currentPage == 1}">
      <a href="#" class="" @click.prevent="changePage(currentPage-1)">
        <svg class="rotate-180">
          <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
        </svg>
      </a>
    </li>

    <li class="" v-if="currentPage > 3">
      <a href="#" class="" @click.prevent="changePage(1)">1</a>
    </li>

    <li class="" v-if="currentPage > 4">
      <a href="#" class="" @click.prevent="changePage(2)">2</a>
    </li>

    <li class=" disabled" v-if="currentPage > 5">
      <a href="#" class="">...</a>
    </li>


    <li class="" v-for="page in pages" :class="{'active': page == currentPage}" :key="page">
      <a href="#" class="" v-if="Math.abs(currentPage - page) < 3"
         @click.prevent="changePage(page)">{{ page }}</a>
    </li>

    <li class=" disabled" v-if="currentPage < pages-4">
      <a href="#" class="">...</a>
    </li>

    <li class="" v-if="currentPage < pages-3">
      <a href="#" class="" @click.prevent="changePage(pages-1)">{{ pages - 1 }}</a>
    </li>

    <li class="" v-if="currentPage < pages-2">
      <a href="#" class="" @click.prevent="changePage(pages)">{{ pages }}</a>
    </li>

    <li class="" :class="{'disabled' : currentPage == pages}">
      <a href="#" class="" @click.prevent="changePage(currentPage+1)">
        <svg>
          <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Pagination",
  computed: {
    ...mapState(['pages', 'currentPage'])
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    }
  }

}
</script>

<style scoped>

</style>