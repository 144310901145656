import axios from "@/api/axios";
import {API} from "@/env";
import Vuex from "@/store";

export default {
    show_FAQ(form){
        const options = {
            method: 'GET',
            url: API + `admin/faqs`,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {...form},
        };

        return new Promise( (resolve,reject) =>  {
           axios(options).then( response => {
              resolve(response);
           }).catch( error => {
               reject(error);
           });
        });
    },

    add_FAQ(data){
        const options = {
            method: 'POST',
            url: API + 'admin/faqs/',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data:data,
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    },

    edit_FAQ(data, id){
        const options = {
            method: 'POST',
            url: API + 'admin/faqs/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data:data,
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    },

    delete_FAQ(id){
        const options = {
            method: 'DELETE',
            url: API + 'admin/faqs/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error.response.data);
            });
        });
    },

    category_FAQ(){
        const options = {
            method: 'GET',
            url: API + 'admin/categories',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    },

    addCategory(data) {
        const options = {
            method: 'POST',
            url: API + `admin/categories`,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data,
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    },

    editCategory(data, id) {
        const options = {
            method: 'POST',
            url: API + `admin/categories/${id}`,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data,
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    },

    deleteCategory(id) {
        const options = {
            method: 'Delete',
            url: API + `admin/categories/${id}`,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise( (resolve,reject) =>  {
            axios(options).then( response => {
                resolve(response.data);
            }).catch( error => {
                reject(error);
            });
        });
    }


}