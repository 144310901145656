import axios from "@/api/axios";
import {API} from "@/env";
import Vuex from "@/store";

export default {

    /**
     * Get all website settings
     * @api {GET} admin/settings/1/edit
     * @returns {Promise}
     */

    showSettings(){

        const options = {
            method: 'GET',
            url: API + 'admin/settings/1/edit',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve,reject) => {
           axios(options).then( response =>  {
              resolve(response.data)
           }).catch( error => {
               reject(error.data.response);
          });
        });

    },

    /**
     * Update website settings
     * @api {POST} admin/settings/1
     * @returns {Promise}
     * @params data
     */

    updateSettings(data){

        const options = {
            method: 'POST',
            url: API + 'admin/settings/1',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data:data
        };

        return new Promise((resolve,reject) => {
            axios(options).then( response =>  {
                resolve(response.data)
            }).catch( error => {
                reject(error.response.data);
            });
        });

    }


}