<template>
  <div class="event-edit">

    <SpinnerLoading v-if="loading"></SpinnerLoading>

    <div class="mt-5 form-model-container" v-else>
      <form @submit.prevent="submit">
        <div class="row m-0">
          <div class="p-0 pr-2 col-sm-8">
            <!-- Event Name -->
            <b-form-group
                class=""
                id="name"
                label="Event Name"
                label-for="question">
              <b-form-input
                  v-model="form.title"
                  id="question"
                  type="text"
                  placeholder="Event Name"
                  required
              ></b-form-input>
              <div class="error" v-if="error.title">
                <p class="m-1 text-danger" v-for="item in error.title" :key="item.id">{{item}}</p>
              </div>
            </b-form-group>

            <!-- Event Details -->
            <b-form-group
                class=""
                id="name"
                label="Details"
                label-for="question">
              <b-form-textarea
                  v-model="form.details"
                  id="question"
                  type="text"
                  placeholder="Add Details"
                  required
              ></b-form-textarea>
              <div class="error" v-if="error.details">
                <p class="m-1 text-danger" v-for="item in error.details" :key="item.id">{{item}}</p>
              </div>
            </b-form-group>
          </div>

          <div class="p-0 col-sm-4">
            <!-- image -->
            <div class="form-group">
              <label>Upload Image</label>
              <div class="display-image">
                <b-form-file @change="imageUploaded" ref="myFiles" v-model="image" plain></b-form-file>
                <img v-if="url" :src="url" alt="">
                <svg>
                  <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
                </svg>
              </div>
              <div class="error" v-if="error.image">
                <p class="m-1 text-danger" v-for="item in error.image" :key="item.id">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Location -->
        <!--        <b-form-group
                    id="name"
                    label="Location"
                    label-for="Location">
                  <b-form-input
                      v-model="form.location"
                      id="question"
                      type="text"
                      placeholder="Add Location"
                      required
                  ></b-form-input>
                  <div class="error" v-if="error.location">
                    <p class="m-1 text-danger" v-for="item in error.location" :key="item.id">{{item}}</p>
                  </div>
                </b-form-group>-->

        <!-- time -->
        <div class="event-data">
          <div>
            from
          </div>
          <div class="input-data">
            <b-form-datepicker v-model="form.start_date" id="datepicker-invalid" :state="false" class="mb-2"></b-form-datepicker>
            <div class="error" v-if="error.start_date">
              <p class="m-1 text-danger" v-for="item in error.start_date" :key="item.id">{{item}}</p>
            </div>
          </div>
          <div>
            to
          </div>
          <div class="input-data">
            <b-form-datepicker v-model="form.end_date"  :state="false" class="mb-2"></b-form-datepicker>
            <div class="error" v-if="error.end_date">
              <p class="m-1 text-danger" v-for="item in error.end_date" :key="item.id">{{item}}</p>
            </div>
          </div>
        </div>

        <!-- submit button -->
        <div class="mt-4 text-center">
          <button class="rounded-button" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SpinnerLoading from "@/components/Loading/SpinnerLoading";

export default {
  components: {SpinnerLoading},
  data() {
    return {
      form: {},
      image:null,
      error:[],
      url:null,
      modalShow: true,
      loading:true,
    }
  },
  methods:{
    imageUploaded(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    submit(){
      let form_data = new FormData();
      form_data.append('title',this.form.title);
      form_data.append('details',this.form.details);
      if(this.image) form_data.append('image',this.image);
      form_data.append('start_date',this.form.start_date);
      form_data.append('end_date',this.form.end_date);
      form_data.append('_method','put');

      this.$API.globalEvent.update_event(this.$route.params.id,form_data)
          .then(response => {
            this.$toastr.s(response.message);
          })
          .catch(error => {
            this.error = error.data.message;
          });
    }
  },
  mounted() {
    this.$API.globalEvent.show_event(this.$route.params.id)
    .then(response => {
      this.loading = false;
      this.url = response.data.image;
      Vue.set(this.form,'title',response.data.title);
      Vue.set(this.form,'details',response.data.details);
      Vue.set(this.form,'start_date',response.data.start_date);
      Vue.set(this.form,'end_date',response.data.end_date);
    })
    .catch(() => {

    });
  }
}
</script>

