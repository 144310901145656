<template>
  <div class="edit-competitions">
    <BlockUI message="Loading Competition Data ..." v-if="loading"></BlockUI>
    <form @submit.prevent="submit">
      <!-- image -->
      <div class="form-group">
        <label>Upload Image</label>
        <div class="display-image">
          <b-form-file ref="myFiles" plain v-model="form.mainImage" @change="imageUploaded"></b-form-file>
          <img :src="mainUrl" alt="">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
          </svg>
        </div>

        <div class="sub-images d-flex flex-wrap">
          <div class="sub-image" v-for="(realImage, index) in realImages" :key="index">
            <img v-if="realImage.hasOwnProperty('image')" :src="realImage.image" alt="">
            <img v-else :src="realImage" alt="">
            <a href="javascript:void(0)" class="delete-image"
               @click.prevent="deleteImage(realImage.id, index)">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#trash"></use>
              </svg>
            </a>
          </div>
          <div class="add-img">
            <b-form-file ref="myFiles" plain @change="subImageUploaded"></b-form-file>
            <svg>
              <use xlink:href="@/assets/images/sprite.svg#add"></use>
            </svg>
          </div>
        </div>
      </div>

      <!-- game guide -->
      <div class="form-group">
        <label>Game Guide</label>
        <div class="sub-images d-flex flex-wrap">
          <div v-for="(realImage, index) in gameGuideRealImgs" :key="index" id="upload-pdf">
            <div class="sub-image">
              <img v-if="realImage.hasOwnProperty('image')" :src="realImage.image" alt="">
              <img v-else :src="realImage" alt="">
              <a href="javascript:void(0)" class="delete-image"
                 @click.prevent="deleteGuideImage(index)">
                <svg>
                  <use xlink:href="@/assets/images/sprite.svg#trash"></use>
                </svg>
              </a>
            </div>
            <input type="text" class="form-control" v-model="gameGuideImgs[index].title" placeholder="Title"
                   required :disabled="gameGuideImgs[index].id">
            <div class="video-file mt-2">
              <svg>
                <use xlink:href="@/assets/images/sprite.svg#upload"></use>
              </svg>
              <template v-if="gameGuideImgs[index].id">
                <a :href="gameGuideImgs[index].link" target="_blank">PDF File</a>
              </template>
              <template v-else>
                <template v-if="gameGuideImgs[index].name">{{ gameGuideImgs[index].name }}</template>
                <template v-else>Read PDF</template>
                <b-form-file required accept="application/pdf" v-model="gameGuideImgs[index].file"
                             @change="fileUploaded($event, index)"></b-form-file>

                <p class="text-danger small" v-if="errors.hasOwnProperty('details.' + index + '.link')">{{errors['details.' + index + '.link'][0]}}</p>
              </template>
            </div>
          </div>
          <div class="add-img">
            <b-form-file ref="myFiles" plain @change="gameGuideUploaded"></b-form-file>
            <svg>
              <use xlink:href="@/assets/images/sprite.svg#add"></use>
            </svg>
          </div>
        </div>
      </div>

      <!-- title -->
      <b-form-group
          id="title"
          label="Title"
          label-for="title">
        <b-form-input
            id="email"
            required
            v-model="form.name"
            type="text" @input="delete errors.name"
            placeholder="Enter Title"
        ></b-form-input>
        <p class="text-danger" v-if="errors.hasOwnProperty('name')">{{ errors['name'][0] }}</p>
      </b-form-group>

      <!-- long description  -->
      <b-form-group
          id="long_description"
          label="Long Description "
          label-for="long_description">
        <quill-editor
            :content="form.long_description"
            @change="onEditorChange($event)">
        </quill-editor>
        <p class="text-danger" v-if="errors.hasOwnProperty('long_description')">{{ errors['long_description'][0] }}</p>
      </b-form-group>

      <!-- short description  -->
      <b-form-group
          id="short_description"
          label="Short Description "
          label-for="short_description">
        <b-form-input
            required
            id="long_description"
            v-model="form.short_description"
            type="text" @input="delete errors.short_description"
            placeholder="Enter Short Description"
        ></b-form-input>
        <p class="text-danger" v-if="errors.hasOwnProperty('short_description')">{{
            errors['short_description'][0]
          }}</p>
      </b-form-group>

      <!-- advantages -->
      <div class="form-group">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <label>Missions</label>
          <button class="wro-btn primary add-competitions" @click.prevent="form.missions.push({key: '', value: ''})"
                  v-if="form.missions.length == 0">
            +
          </button>
        </div>

        <div v-for="(mission, index) in form.missions" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <div class="row flex-1">
              <div class="col-md-6">
                <label class="mb-0">Key</label>
                <input type="text"
                       class="mb-2 form-control"
                       @input="delete errors['missions.' + index]"
                       v-model="form.missions[index].key" required>
              </div>
              <div class="col-md-6">
                <label class="mb-0">Value</label>
                <input type="text"
                       class="mb-2 form-control"
                       @input="delete errors['missions.' + index]"
                       v-model="form.missions[index].value" required>
              </div>
            </div>
            <button class="wro-btn primary add-competitions" @click.prevent="form.missions.push({key: '', value: ''})"
                    v-if="index == form.missions.length-1">
              +
            </button>

            <button class="wro-btn primary add-competitions remove" @click.prevent="deleteMission(index)" v-else>
              <svg class="delete-icon">
                <use xlink:href="@/assets/images/sprite.svg#delete"></use>
              </svg>
            </button>
          </div>
          <p class="text-danger" v-if="errors.hasOwnProperty('missions.' + index)">
            {{ errors['missions.' + index][0] }}</p>
        </div>
      </div>

      <b-form-group>
        <label>Activity Code </label>
        <b-form-checkbox :checked="form.promocode_required == 1 ? true: false" switch v-model="form.promocode_required">
          <template v-if="form.promocode_required == 1">Required</template>
          <template v-else>Not Required</template>
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox :checked="form.active == 1 ? true: false" switch v-model="form.active">
          <template v-if="form.active == 1">Active</template>
          <template v-else>Inactive</template>
        </b-form-checkbox>
      </b-form-group>

      <div class="d-flex align-items-center">
        <label for="site-color" class="mr-3 mb-0">Competition background color</label>
        <v-input-colorpicker id="site-color" v-model="form.color"/>
      </div>

      <!-- submit -->
      <div class="text-center">
        <button class="rounded-button" type="submit">
          Save
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "EditCompetition",
  data() {
    return {
      errors: {},
      form: {
        color: '#0093d2',
        missions: [
          {
            key: null,
            value: '',
          }
        ]
      },
      loading: false,
      mainUrl: null,
      subImages: [],
      realImages: [],
      deletedIds: [],
      gameGuideImgs: [],
      gameGuideRealImgs: [],
      pdfFileName: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.$API.competitions.showCompetitions(this.$route.params.id)
          .then(response => {
            this.form.id = response.id;
            this.mainUrl = response.image;
            response.photos.map(photo => {
              this.realImages.push(photo)
            });
            response.details.map(photo => {
              this.gameGuideImgs.push(photo)
              this.gameGuideRealImgs.push(photo.image)
            });
            this.form.name = response.name;
            this.form.long_description = response.long_description
            this.form.short_description = response.short_description
            this.form.color = response.color
            this.form.active = response.active == 1 ? true : false;
            this.form.promocode_required = response.promocode_required == 1 ? true : false;
            Vue.set(this.form, 'missions', response.missions);

          })
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.form.long_description = html
    },
    imageUploaded(e) {
      let file = e.target.files[0];
      this.mainUrl = URL.createObjectURL(file);
    },

    subImageUploaded(event) {
      this.subImages.push(event.target.files[0])
      let file = event.target.files[0];
      this.realImages.push(URL.createObjectURL(file));
    },

    deleteImage(id, index) {
      this.realImages.splice(index, 1);
      this.subImages.splice(index, 1)
      this.$API.competitions.deleteCompetitionImage(id)
    },
    deleteMission(index) {
      if (this.form.missions[index].id) {
        this.$API.competitions.deleteMission(this.form.missions[index].id)
      }
      Vue.delete(this.form.missions, index);
    },


    gameGuideUploaded(event) {
      this.gameGuideImgs.push({
        photo: event.target.files[0],
        title: null,
        name: null
      })
      let file = event.target.files[0];
      this.gameGuideRealImgs.push(URL.createObjectURL(file));
    },

    fileUploaded(e, index) {
      this.gameGuideImgs[index].name = e.target.files[0].name
    },

    deleteGuideImage(index) {
      this.$API.competitions.deleteGameGuide(this.gameGuideImgs[index].id)
      .then(() => {
        this.gameGuideRealImgs.splice(index, 1);
        this.gameGuideImgs.splice(index, 1)
      })
    },

    submit() {
      this.loading = true;
      let formData = new FormData();
      if (this.form.mainImage) formData.append('image', this.form.mainImage);
      formData.append('name', this.form.name);
      formData.append('long_description', this.form.long_description);
      formData.append('short_description', this.form.short_description);
      formData.append('color', this.form.color);
      formData.append('active', this.form.active ? 1 : 0);
      formData.append('promocode_required', this.form.promocode_required ? 1 : 0);

      this.form.missions.map((miss, index) => {
        formData.append(`missions[${index}][key]`, miss['key']);
        formData.append(`missions[${index}][value]`, miss['value']);
      })

      if (this.subImages.length > 0) {
        this.subImages.map((subImage, index) => {
          formData.append(`images[${index}]`, subImage)
        })
      }

      if (this.gameGuideImgs.length > 0) {
        this.gameGuideImgs.map((el, index) => {
          if (!el.id) {
            formData.append(`details[${index}][title]`, el.title)
            formData.append(`details[${index}][link]`, el.file)
            formData.append(`details[${index}][photo]`, el.photo)
          }
        })
      }

      if (this.form.id) {
        formData.append('_method', 'PUT');
        this.$API.competitions.updateCompetition(formData, this.form.id)
            .then(response => {
              this.loading = false;
              this.form = response.data;
              this.form.active = response.data.active == 1 ? true : false
              this.$toastr.s(response.message)
            })
            .catch(error => {
              this.errors = error
              this.loading = false
            })
      } else {
        this.$API.competitions.addCompetition(formData)
            .then(response => {
              this.loading = false;
              this.form = response.data;
              this.form.active = response.data.active == 1 ? true : false
              this.$toastr.s(response.message)
            })
            .catch(error => {
              this.errors = error
              this.loading = false
            })
      }
    }
  }
}
</script>

<style scoped>
.add-competitions {
  width: 52px;
  height: 52px;
  border-radius: 9px;
  font-size: 22px;
  position: relative;
  top: 8px;
  margin-left: 10px;
}

.remove {
  background: none !important;
}

.display-image {
  border-radius: 5px;
  padding: 0;
  min-height: 164px;
  height: auto;
}

.display-image img {
  border-radius: 5px;
}

img {
  width: 100%;
}

#site-color {
  width: 80px;
}

#upload-pdf .video-file .custom-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
  opacity: 0;
}

#upload-pdf .video-file {
  font-size: 16px;
  font-family: Poppins-medium;
  position: relative;
}

#upload-pdf svg {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

</style>