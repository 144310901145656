<template>
  <div>
    <h3>Personal Information</h3>

    <form class="row" @submit.prevent="submit">
      <!-- user avatar -->
      <div class="col-xl-6">
        <label>Upload Image</label>
        <div class="form-group">
          <div class="display-image">
            {{ imageName }}
            <b-form-file @change="imageUploaded" v-model="image" ref="myFiles" plain></b-form-file>
            <svg>
              <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
            </svg>
          </div>
        </div>
      </div>

      <div class="col-xl-6"></div>

      <!-- name -->
      <div class="col-xl-6">
        <b-form-group
            id="name"
            label="Name *"
            label-for="name">
          <b-form-input
              id="email"
              v-model="form.name"
              type="text"
              placeholder="Enter Name"
              required
          ></b-form-input>

        </b-form-group>
      </div>

      <!-- email -->
      <div class="col-xl-6">
        <b-form-group
            id="email"
            label="Email Address *"
            label-for="email">
          <b-form-input
              id="email"
              v-model="form.email"
              type="text"
              placeholder="Enter Email"
              required
          ></b-form-input>

        </b-form-group>
      </div>

      <!-- date of birth -->
      <!--      <div class="col-xl-6">
              <b-form-group
                  id="birthdate"
                  label="Date of Birth"
                  label-for="birthdate">
                <b-form-input
                    name="daterange"
                    id="birthdate"
                    type="text"
                    placeholder="Enter Date of Birth"
                ></b-form-input>

              </b-form-group>
            </div>-->


      <!-- phone number -->
      <!--      <div class="col-xl-6">
              <b-form-group
                  id="phone"
                  label="Mobile Number"
                  label-for="phone">
                <b-form-input
                    id="phone"
                    v-model="form.phone"
                    type="number"
                    placeholder="Enter Mobile Number"
                ></b-form-input>

              </b-form-group>
            </div>-->

      <!-- submit button -->
      <div class="col-12 text-center">
        <button class="rounded-button" type="submit">
          Save
        </button>
      </div>

    </form>
  </div>
</template>

<script>
export default {
  name: "Profile",

  props: ['userData'],
  data() {
    return {
      image: null,
      imageName: null,
      form: {
        image: ''
      }
    }
  },
  mounted() {
    this.form = {...this.userData}
    /*let that = this;

    $('input[name="daterange"]').daterangepicker({
      opens: 'left',
      singleDatePicker: true,
      maxDate: moment(),
      autoApply: true,
      showDropdowns: true,
      locale: {
        format: 'YYYY-MM-DD'
      }
    }, function(start) {
      Vue.set(that.form, 'birth_date', start.format('YYYY-MM-DD'))
    });*/
  },
  watch: {
    'userData'() {
      this.form = {...this.userData}
    }
  },
  methods: {
    /**
     * submit update profile
     */
    submit() {
      var formData = new FormData();
      if (this.image) formData.append('image', this.image);
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      this.$API.profile.updateProfile(formData)
          .then(response => {
            this.$toastr.s(response.message);
            this.$emit('reload')
          })
    },

    imageUploaded() {
      this.imageName = event.target.files[0].name
    }
  }
}
</script>

<style scoped>

</style>