import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get all competitions
     * @api {GET} admin/competitions
     * @param {number} page
     * @returns {Promise} represents list of organizers
     */
    getCompetitions(page) {
        const options = {
            method: 'GET',
            url: API + 'admin/competitions',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {'page': page}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                }).catch(error => {
                reject(error)
            })
        })
    },


    /**
     * Add competition
     * @api {POST} admin/competitions
     * @param {object} data
     * @returns {Promise}
     */
    addCompetition(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/competitions',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Show competition details
     * @api {GET} admin/competitions/:id
     * @param {number} competitionId
     * @returns {Promise}
     */
    showCompetitions(competitionId) {
        const options = {
            method: 'GET',
            url: API + 'admin/competitions/' + competitionId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Update competition
     * @api {POST} admin/competitions/:id
     * @param {object} data
     * @returns {Promise}
     */
    updateCompetition(data, id) {
        const options = {
            method: 'POST',
            url: API + 'admin/competitions/' + id,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Delete Competition
     * @api {DELETE} admin/plans/:id
     * @param {number} competitionId
     * @returns {Promise<unknown>}
     */
    deleteCompetition(competitionId){
        const options = {
            method: 'DELETE',
            url: API + 'admin/competitions/' + competitionId ,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },


    /**
     * Delete Competition image
     * @api {DELETE} admin/photos/:id
     * @param {number} imageID
     * @returns {Promise<unknown>}
     */
    deleteCompetitionImage(imageID){
        const options = {
            method: 'DELETE',
            url: API + 'admin/photos/' + imageID ,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Delete Competition image
     * @api {DELETE} admin/photos/:id
     * @param {number} id
     * @returns {Promise<unknown>}
     */
    deleteMission(id){
        const options = {
            method: 'DELETE',
            url: API + 'admin/missions/' + id ,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Delete game guide
     * @param {number} id
     * @returns {Promise}
     */
    deleteGameGuide(id){
        const options = {
            method: 'DELETE',
            url: API + 'admin/competition_details/' + id ,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    }
}