<template>
  <div class="page-shadow contact-us">
    <BlockUI message="Loading Plan Data..." v-if="loading"></BlockUI>

    <template v-else>
      <div class="profile-data-wrapper">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="profile-data">
            <div class="d-flex flex-wrap">
              <div class="profile-image">
                <img src="@/assets/images/competitions.png" alt="">
              </div>
              <div>
                <h3>{{ data.name }}</h3>
                <p class="building mb-0">
                  {{ data.email }}
                </p>
                <p class="lightgray-text">
                  {{ data.date }}
                </p>
              </div>
            </div>
          </div>

          <button class="rounded-button" @click.prevent="deleteMessages">
            <svg class="icon-12">
              <use xlink:href="@/assets/images/sprite.svg#trash"></use>
            </svg>
            Delete
          </button>
        </div>
      </div>

      <div class="details">
        <span class="d-block"> {{ data.subject }}</span>
        <hr class="my-2">
        <p>
          {{ data.message }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    data: {},
    loading: true,
  }),
  name: "Details",
  mounted() {
    let messageId = this.$route.params.id;
    if (messageId) {
      this.$API.contacts.readMessage(messageId)
          .then(response => {
            this.data = response
            this.loading = false;
          }).catch(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    deleteMessages() {
      this.loading = true
      this.$API.contacts.deleteMessage(this.$route.params.id)
          .then(response => {
            this.$toastr.s(response)
            this.$router.push('/contact-us')
          })
          .catch(error => {
            this.loading = false;
            this.$toastr.e(error)
          });
    }
  }
}
</script>

<style scoped>
.icon-12 {
  fill: #ffffff;
}
</style>