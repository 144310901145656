<template>
  <div class="global">
    <!-- events list -->
      <div class="row m-0">
        <SpinnerLoading v-if="loading"></SpinnerLoading>
        <div class="col-md-6 col-lg-4 col-xl-3 row-item" v-for="item in globalEvent" :key="item.id" v-else>
          <div class="events-list">
            <div class="img-event">
              <div class="more" @click.prevent.stop="">
                <a href="javascript:void(0)"
                   @click.prevent="showMore == item.id ? showMore = null: showMore = item.id">
                  <svg class="small dark">
                    <use xlink:href="@/assets/images/sprite.svg#more"></use>
                  </svg>
                </a>
                <ul class="more-actions" :class="{'show': showMore == item.id}">
                  <li>
                    <router-link :to="'/events/edit/' + item.id">Edit</router-link>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="toDelete = item" v-b-modal="'delete-event'">Delete</a>
                  </li>
                </ul>
              </div>
              <img :src="item.image">
            </div>
            <div class="text-event">
              <p class="event-head-text">
                {{item.title}}
              </p>
              <p class="text">
                {{item.details}}
              </p>
            </div>
            <div>
              <!-- details -->
              <div class="events-details">
                <div>
                  <img src="@/assets/icons/events/calender.png">
                  <p>{{ item.start_date }}</p>
                </div>
                <div>
                  <img src="@/assets/icons/events/calender.png">
                  <p>{{item.end_date}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <b-modal id="delete-event" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.title" @cancelDelete="toDelete = null" @confirmDelete="deleteEvent"></Delete>
    </b-modal>

  </div>
</template>

<script>
import SpinnerLoading from "@/components/Loading/SpinnerLoading";
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  components: {SpinnerLoading, Delete},
  data:() => ({
    loading:true,
    showMore: null,
    event_active_status:'all',
    global_events:[],
    toDelete: null
  }),
  methods:{
    active_event_status(listName){
      this.event_active_status = listName;
    },
    get_events() {
      this.$API.globalEvent.get_events()
          .then(response => {
            this.loading = false;
            this.$store.commit('set_global_event',response.data);
          })
          .catch(() => {

          });
    },
    deleteEvent(){
      this.$API.globalEvent.delete_event(this.toDelete.id)
      .then(response => {
        this.$toastr.s(response.message);
        this.toDelete = null;
        this.get_events()
      })
      .catch(() => {

      });
    }
  },
  computed:{
    globalEvent() {
      return this.$store.state.global_event;
    }
  },
  mounted() {
    this.get_events();
    $('body').click(() => {
      this.showMore = null
    })
  }
}
</script>

<style scoped>

.img-event .more {
  position: absolute;
  right: 2px;
  top: 15px;
  color: #ffffff;
}

.img-event .more svg {
  fill: #ffffff;
}

.img-event img{
  height: 206px!important;
}

.event-active-tab:after{
  content: '';
  position: absolute;
  bottom: -11px;
  left: 50%;
  width: 77px;
  border: 2px solid #252634;
  transform: translate(-50%);
}

.more .more-actions {
  right: 10px;
  left: inherit;
}

</style>