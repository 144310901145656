<template>
  <div class="events">
    <div class="mb-30">
      <EventModal></EventModal>
    </div>

    <!-- chose events global || National -->
    <div class="chose-events-wrapper">
      <div class="chose-events">
        <div class="item" :class="event_tybe == 'Global' ? 'active' : ''" @click="active_event_tybe('Global')">
          <div class="img">
            <img src="@/assets/icons/events/global.png">
          </div>
          <p>Global Events</p>
        </div>
        <div class="item" :class="event_tybe == 'National' ? 'active' : ''" @click="active_event_tybe('National')">
          <div class="img">
            <img src="@/assets/icons/events/international.png">
          </div>
          <p>National Organizers</p>
        </div>
      </div>
    </div>

    <!-- Toggle Between Global & National Events -->
    <component :is="component"></component>

  </div>
</template>

<script>
import National from "@/components/Event/National"
import Global from "@/components/Event/Global"
import EventModal from "@/components/Event/EventModal";
export default {
  data: () => ({
    event_tybe: 'National',
    component:'National',
  }),
  props:[],
  components:{EventModal, National, Global},
  methods:{
      active_event_tybe(e){
        this.event_tybe = e;
        this.component = e;
      },
    },
  }
</script>

<style scoped>
.rounded-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.active{
  border: 2px solid #0093D2;
}

.active p{
  color: #1C2136;
  font-weight: bold;
}

</style>