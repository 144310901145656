import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get all plans
     * @api {GET} admin/plans
     * @param {number} planId
     * @returns {Promise}
     */
    getAllPlans(planId) {
        let obj = {};
        if (planId) obj['plan_id'] = planId
        const options = {
            method: 'GET',
            url: API + 'admin/plans',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {...obj}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Update plan
     * @api {POST} admin/plans/:id
     * @param planId
     * @para {object} data
     * @returns {Promise<unknown>}
     */
    updatePlan(planId, data) {
        const options = {
            method: 'POST',
            url: API + 'admin/plans/'+ planId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },


    /**
     * Add plan
     * @api {POST} admin/plans/:id
     * @param data
     * @returns {Promise<unknown>}
     */

    addPlan(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/plans/',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Delete plan
     * @api {DELETE} admin/plans/:id
     * @param
     * @returns {Promise<unknown>}
     */
    deletePlan(planId){
        const options = {
            method: 'DELETE',
            url: API + 'admin/plans/' + planId ,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })


    }
}