<template>
  <div class="sidebar">
    <div class="d-flex justify-content-between align-items-center logo-wrapper">
      <div class="logo">
        <img src="@/assets/images/logo-white.png" alt="">
      </div>

      <a href="javascript:void(0)" @click.prevent="minimizeSidebar">
        <svg class="sandwich-icon">
          <use xlink:href="@/assets/images/sprite.svg#sandwich-icon"></use>
        </svg>
      </a>
    </div>

    <p class="sidebar-padding main">Main</p>
    <ul>
      <li>
        <router-link to="/" exact>
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#dashboard"></use>
          </svg>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/organizers" exact :class="{'active-link': routePrefix == 'organizers'}">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#national-org"></use>
          </svg>
          <span>Mange National Org</span>
        </router-link>
      </li>
      <li>
        <router-link to="/ambassadors" exact :class="{'active-link': routePrefix == 'ambassadors'}">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#national-org"></use>
          </svg>
          <span>Mange Ambassadors </span>
        </router-link>
      </li>

      <li>
        <router-link to="/training-support">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#training-support"></use>
          </svg>
          <span>Training And Support</span>
        </router-link>
      </li>
      <li>
        <router-link to="/plans">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#finance-system"></use>
          </svg>
          <span>Plans</span>
        </router-link>
      </li>
       <li>
        <router-link to="/events">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#events"></use>
          </svg>
          <span>Events</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/notifications">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#notification"></use>
          </svg>
          <span>Notifications</span>
        </router-link>
      </li> -->
      <li>
        <router-link to="/competitions">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#competitions"></use>
          </svg>
          <span>Competitions</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{name:'Payment'}">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#finance-system"></use>
          </svg>
          <span>Payment</span>
        </router-link>
      </li>
      <li>
        <router-link to="/faq" exact :class="{'active-link': routePrefix == 'faq'}">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#faq"></use>
          </svg>
          <span>FAQ</span>
        </router-link>
      </li>
    </ul>

    <p class="sidebar-padding main">Help</p>
    <ul>
      <li>
        <router-link to="/profile" exact>
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#profile"></use>
          </svg>
          <span>Profile</span>
        </router-link>
      </li>

      <li>
        <router-link to="/settings" exact>
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#settings"></use>
          </svg>
          <span>Settings</span>
        </router-link>
      </li>

      <li>
        <router-link to="/contact-us" exact :class="{'active-link': routePrefix == 'contact-us'}">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#contact-us"></use>
          </svg>
          <span>Contact Us</span>
        </router-link>
      </li>

<!--      <li>
        <router-link to="/support-ticket" exact>
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#support-ticket"></use>
          </svg>
          <span>Support Ticket</span>
        </router-link>
      </li>-->

    </ul>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "TheSidebar",
  data() {
    return {
      routePrefix: null
    }
  },
  methods: {
    minimizeSidebar() {
      $('body').toggleClass('sidebar-xs')
    }
  },
  watch: {
    '$route'() {
      this.routePrefix = this.$route.path.split("/")[1]
    }
  }
}
</script>

<style scoped>

</style>