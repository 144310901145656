import axios from "@/api/axios";
import {API} from "@/env";
import Vuex from "@/store";

export default {
    /**
     * Get All Contacts
     * @api {POST} admin/contacts
     * @param {number} page
     * @returns {Promise}
     */
    getMessages(page) {

        const option = {
            method: 'GET',
            url: API + 'admin/contacts',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {'page': page}
        }

        return new Promise ( (resolve,reject) => {
            axios(option).then(response => {
                resolve(response.data.data.alldata);
                Vuex.commit('setPages', response.data.data.meta.lastPage)
                Vuex.commit('setCurrentPage', response.data.data.meta.currentPage)
            }).catch(error => {
                reject(error.response.data.message);
            });
        })
    },

    /**
     * Show Target Message
     * @api {POST} admin/contacts/:id
     * @param {number} messageId
     * @returns {Promise}
     */
     readMessage(messageId) {
        const option = {
            method: 'GET',
            url: API + 'admin/contacts/' + messageId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        }

        return new Promise( (resolve,reject) =>  {
           axios(option).then(response => {
              resolve(response.data.data);
           }).catch(error => {
              reject(error.response.data.message);
           });
        });
    },

    /**
     * Delete Target Message
     * @api {DELETE} admin/contacts/:id
     * @param {number} messageId
     * @returns {Promise}
     */
    deleteMessage(messageId) {
        const option = {
            method: 'DELETE',
            url: API + 'admin/contacts/' + messageId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        }

        return new Promise( (resolve,reject) =>  {
            axios(option).then(response => {
                resolve(response.data.message);
            }).catch(error => {
                reject(error.response.data.message);
            });
        });
    }

}

