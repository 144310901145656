import axios from "../axios";
import {API} from '../../env'
import Vuex from '../../store'


export default {
    /**
     * Add New Event
     * @api {POST} admin/events
     * @param,
     * @returns,
     */
    add_event(data){
        const options = {
            method: 'POST',
            url: API + 'admin/events',
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            data: data,
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                   reject(error.response)
            })
        })
    },

    /**
     * Get All Event
     * @api {GET} admin/events
     * @param,
     * @returns,
     */
    get_events(){
        const options = {
            method: 'GET',
            url: API + 'admin/events',
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Edit Event
     * @api {POST} admin/events/:id
     * @param id,
     * @returns,
     */
    update_event(id,data) {
        const options = {
            method: 'POST',
            url: API + 'admin/events/'+ id,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params:id,
            data:data,
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Show Event
     * @api {POST} admin/events/:id
     * @param id,
     * @returns,
     */

    show_event(id) {
        const options = {
            method: 'GET',
            url: API + 'admin/events/'+ id,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params:id,
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /**
     * Delete Event
     * @api {DELETE} admin/events
     * @param,
     * @returns,
     */

    delete_event(id) {
        const options = {
            method: 'DELETE',
            url: API + 'admin/events/'+ id,
            headers: {'content-type': 'application/json',Authorization: 'Bearer' + Vuex.getters.token},
            params:id,
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

}