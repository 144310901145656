<template>
  <div class="national">
    <!-- tabs -->
    <div class="tabs">
      <div class="tabs-wrapper">
        <ul>
          <li :class="activeTab == '' ? 'event-active-tab' : ''"
              @click.prevent="activeTab = ''">
            All Events
          </li>
          <li :class="activeTab == '1' ? 'event-active-tab' : ''"
              @click.prevent="activeTab = '1'">Approved
          </li>
          <li :class="activeTab == '2' ? 'event-active-tab' : ''"
              @click.prevent="activeTab = '2'">Refused
          </li>
          <li :class="activeTab == '0' ? 'event-active-tab' : ''"
              @click.prevent="activeTab = '0'">Pending
          </li>
        </ul>
      </div>
    </div>
    <SpinnerLoading v-if="loading"></SpinnerLoading>
    <!-- Events List  -->
    <template v-if="events.length > 0">
      <div class="events-list" v-for="item in events" :key="item.id">
        <div class="row row-event-item">
          <!-- img -->
          <div class="col-md-3 img-event"><img :src="item.image"></div>
          <!-- text -->
          <div class="col-md-5 text-event">
            <p class="event-head-text">
              {{ item.title }}
            </p>
            <p class="text">
              {{ item.description }}
            </p>

            <!-- details -->
            <div class="p-0 mt-3 events-details">
              <div>
                <img src="@/assets/icons/events/location.png">
                <p>{{ item.organizer.country }}</p>
              </div>
              <div>
                <img src="@/assets/icons/events/calender.png">
                <p>{{ item.start_date }}</p>
              </div>
              <div>
                <img src="@/assets/icons/events/calender.png">
                <p>{{ item.end_date }}</p>
              </div>
            </div>
          </div>

          <!-- event actions -->
          <div class="col-md-4 action-event">
            <button @click="changeStatus(item.id, 2)" class="rounded-button refuse"
                    v-if="item.active == 1 || item.active == 0">
              Refuse
            </button>
            <button @click="changeStatus(item.id, 1)" class="rounded-button accept"
                    v-if="item.active == 2 || item.active == 0">
              Active
            </button>
          </div>
        </div>
        <!-- More -->
        <div class="more" @click.prevent.stop="">
          <a href="javascript:void(0)"
             @click.prevent="showMore == item.id ? showMore = null : showMore = item.id">
            <svg class="small dark">
              <use xlink:href="@/assets/images/sprite.svg#more"></use>
            </svg>
          </a>
          <ul class="more-actions" :class="{'show': showMore == item.id}">
            <li>
              <router-link :to="'/events/edit-National-Events/' + item.id">Edit</router-link>
            </li>
            <li>
              <a href="javascript:void(0)" @click="toDelete = item" v-b-modal="'delete-event'">Delete</a>
            </li>
          </ul>
        </div>
      </div>
      <Pagination @changePage="changePage"></Pagination>
    </template>
    <p v-else class="text-center">There are no events</p>

    <b-modal id="delete-event" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.title" @cancelDelete="toDelete = null" @confirmDelete="deleteEvent"></Delete>
    </b-modal>

  </div>
</template>

<script>
import SpinnerLoading from "@/components/Loading/SpinnerLoading";
import $ from "jquery";
import Delete from "@/components/Modals/Delete";
import Pagination from "@/components/Pagination";

export default {
  components: {SpinnerLoading, Delete, Pagination},
  data: () => ({
    loading: true,
    events: [],
    showMore: null,
    toDelete: null,
    activeTab: ''
  }),
  mounted() {
    this.getEvents({}, 1)

    $('body').click(() => {
      this.showMore = null
    })
  },
  watch: {
    'activeTab'() {
      this.loading = true;
      this.events = [];
      this.getEvents({active: this.activeTab}, 1)
    }
  },
  methods: {
    changeStatus(id, status) {
      this.$API.nationalEvent.changeStatus(id, status)
          .then(() => {
            this.getEvents({active: this.activeTab}, 1)
          })
    },
    getEvents(tab, page) {
      this.loading = true;
      this.$API.nationalEvent.get_events(tab, page)
          .then(response => {
            this.loading = false;
            this.events = response.data;
            this.loading = false;
          }).catch(() => {
        this.loading = false;
      })
    },
    changePage(page) {
      this.getEvents({active: this.activeTab}, page)
    },
    deleteEvent() {
      this.$API.nationalEvent.delete_event(this.toDelete.id)
          .then(response => {
            this.$toastr.s(response.message);
            this.toDelete = null;
            this.getEvents({active: this.activeTab}, 1)
          })
    }
  }
}
</script>

<style scoped>

.event-active-tab:after {
  content: '';
  position: absolute;
  bottom: -11px;
  left: 50%;
  width: 77px;
  border: 2px solid #252634;
  transform: translate(-50%);
}

.accept {
  background: #18A864;
}

.refuse {
  background: #E94D61;
}

.more {
  position: absolute;
  right: 2px;
  top: 15px;
  color: #ffffff;
}

.more .more-actions {
  right: 10px;
  left: inherit;
}


</style>