<template>
  <div>
    <!-- loading -->
    <BlockUI message="Sending Data..." v-if="loading"></BlockUI>
    <!-- loading -->

    <!-- Close Form -->
    <img src="@/assets/icons/cancel.png" @click="modalShow = false">
    <!-- End close form -->

    <div class="form-model-container">
      <div class="text-header">
        <p>FAQ</p>
      </div>
      <form @submit.prevent="submit">

        <!-- Type -->
        <b-form-group label="Type:" label-for="input-3">
          <b-form-select
              id="input-3"
              v-model="form.category_id"
              :options="categories"
              required
          ></b-form-select>
        </b-form-group>

        <!-- Question -->
        <b-form-group
            id="name"
            label="Question"
            label-for="question">
          <b-form-input
              v-model="form.question"
              id="question"
              type="text"
              placeholder="Question ?"
              required
          ></b-form-input>
        </b-form-group>

        <!-- Add answer  -->
        <b-form-group
            id="name"
            label="Add answer"
            label-for="Add answer">
          <b-form-textarea
              id="answer"
              type="answer"
              placeholder="Add answer"
              v-model="form.answer"
              required
          ></b-form-textarea>
        </b-form-group>

        <!-- Add To -->
        <div class="add-to">
          <b-form-group class="checkbox-form" label="Add To :" id="input-group-4" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                v-model="form.checked"
                id="checkboxes-4"
                :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox class="checkbox-input" value="national">
                national
              </b-form-checkbox>
              <b-form-checkbox value="international">
                international
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

        <!-- submit button -->
        <div class="text-center">
          <button class="rounded-button" type="submit">
            <template v-if="question">Update</template>
            <template v-else>Save</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQModal',
  props: ['question'],
  data() {
    return {
      form: {
        question: '',
        answer: '',
        category_id: null,
        checked: [],
      },
      modalShow: false,
      categories: [{text: 'Select One', value: null},],
      fetch_cat: [],
      loading: false,
    }
  },
  mounted() {
    this.categories = [{text: 'Select One', value: null},];
    this.fetch_cat = this.$store.state.available_cat;
    this.fetch_cat.map(el => {
      this.categories.push({text: el.name, value: el.id});
    });

    if (this.question) {
      this.form['answer'] = this.question['answer']
      this.form['category_id'] = this.question['category_id'];
      this.form['question'] = this.question['question']
      this.form['checked'] = this.question['type']

    }
  },
  methods: {
    submit() {
      this.loading = true;
      const sent_data = new FormData();
      sent_data.append('question', this.form.question);
      sent_data.append('answer', this.form.answer);
      sent_data.append('category_id', this.form.category_id);
      this.form.checked.map((el, index) => {
        sent_data.append('type[' + index + ']', this.form.checked[index]);
      });

      if (!this.question) {
        this.$API.faq.add_FAQ(sent_data)
            .then(response => {
              this.loading = false;
              this.$toastr.s(response.message);
              this.$emit('reGetData');
            })
            .catch(error => {
              this.$toastr.e(error.message);
            });
      } else {
        sent_data.append('_method', 'PUT');

        this.$API.faq.edit_FAQ(sent_data, this.question.id)
            .then(response => {
              this.loading = false;
              this.$toastr.s(response.message);
              this.$emit('reGetData');
            })
            .catch(error => {
              this.$toastr.e(error.message);
            });
      }
    }
  }
}
</script>
