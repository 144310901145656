<template>
  <div class="category">

    <div class="row faq-blocks">
      <div class="col" @click="gettingStart">
        <div class="faq-block" :class="{'active': selectedCategory == 'start'}">
          <svg class="getting-started">
            <use xlink:href="@/assets/images/sprite.svg#getting-started"></use>
          </svg>
          <p>All Questions</p>
        </div>
      </div>
      <div class="col" v-for="item in category" :key="item.id" @click.prevent="selectedCat(item)">
        <div class="faq-block" :class="{'active': selectedCategory == item.id}">
          <div class="more" @click.prevent.stop>
            <a href="" @click.prevent.stop="showMore == item.id? showMore = null: showMore = item.id"
               class="position-relative">
              <svg class="more">
                <use xlink:href="@/assets/images/sprite.svg#more"></use>
              </svg>
            </a>
            <ul class="more-actions" :class="{'show': showMore == item.id}" @click.prevent.stop="">
              <li>
                <a href="javascript:void(0)" @click.prevent.stop="categoryModal = true; categoryToEdit = item">Edit</a>
              </li>
              <li>
                <a href="javascript:void(0)" v-b-modal="'delete-category'"
                   @click.prevent.stop="toDelete = item">Delete</a>
              </li>
            </ul>
          </div>
          <img class="category-logo" :src="item.logo">
          <p> {{ item.name }} </p>
        </div>
      </div>

      <div class="col">
        <a href="javascript:void(0)" class="faq-block add-category" @click.prevent="categoryModal = true">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#add"></use>
          </svg>
          <p>Add Category</p>
        </a>
      </div>
    </div>

    <b-modal hide-footer hide-header v-model="categoryModal">
      <img src="@/assets/icons/cancel.png" @click="categoryModal = false">

      <div class="form-model-container add-category-modal">
        <div class="text-header">
          <p>ADD Category</p>
        </div>
        <form @submit.prevent="submit" class="row align-items-center">
          <!-- category image -->
          <div class="col-lg-6">
            <div class="form-group">
              <label>Category Image</label>
              <div class="display-image">
                <b-form-file ref="myFiles" plain v-model="form.logo" @change="imageUploaded"></b-form-file>
                <img :src="mainUrl" alt="">
                <svg>
                  <use xlink:href="@/assets/images/sprite.svg#upload-file"></use>
                </svg>
              </div>
            </div>
          </div>

          <!-- category title -->
          <div class="col-lg-6">
            <!-- name -->
            <b-form-group
                id="title"
                label="Category Name"
                label-for="title">
              <b-form-input
                  id="title"
                  v-model="form.name"
                  placeholder="Title"
                  required
              ></b-form-input>
            </b-form-group>

            <!-- description -->
            <b-form-group
                id="description"
                label="Description"
                label-for="description">
              <b-form-textarea
                  id="description"
                  v-model="form.description"
                  placeholder="Description"
                  required
              ></b-form-textarea>
            </b-form-group>

          </div>

          <div class="col-12 text-center">
            <button class="rounded-button">Add</button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="delete-category" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.name" @cancelDelete="toDelete = null" @confirmDelete="deleteCategory"></Delete>
    </b-modal>

  </div>
</template>

<script>
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  name: 'ChooseCategory',
  components: {Delete},
  data() {
    return {
      category: [],
      selectedCategory: 'start',
      categoryModal: false,
      mainUrl: null,
      form: {},
      showMore: null,
      categoryToEdit: null,
      toDelete: null
    }
  },
  watch: {
    'categoryToEdit'() {
      if (this.categoryToEdit) {
        this.mainUrl = this.categoryToEdit.logo ? this.categoryToEdit.logo : null;
        this.form.name = this.categoryToEdit.name
        this.form.description = this.categoryToEdit.description
      }
    },
    'categoryModal'() {
      if (!this.categoryModal) {
        this.form = {};
        this.mainUrl = null;
        this.categoryToEdit = null
      }
    }
  },
  methods: {
    getCategories() {
      this.$API.faq.category_FAQ()
          .then(response => {
            this.category = response.data;
            this.$store.commit('set_available_cat', response.data);
          })
          .catch(error => {
            this.$toastr.s(error.response.message);
          });
    },

    selectedCat(item) {
      this.selectedCategory = item.id;
      this.$emit('get_cat_id', item.id);
    },
    gettingStart() {
      this.selectedCategory = 'start';
      this.$emit('get_cat_id', null);
    },

    /**
     * Method invoked when we upload image in category popup
     */
    imageUploaded(e) {
      let file = e.target.files[0];
      this.mainUrl = URL.createObjectURL(file);
    },

    /**
     * Submit form
     */
    submit() {
      let formData = new FormData();
      if (this.form.logo) {
        formData.append('logo', this.form.logo);
      }
      formData.append('name', this.form.name);
      formData.append('description', this.form.description);

      if (!this.categoryToEdit) {
        this.$API.faq.addCategory(formData)
            .then(response => {
              this.$toastr.s(response.message);
              this.categoryModal = false;
              this.getCategories();
            })
      } else {
        formData.append('_method', 'PUT')
        this.$API.faq.editCategory(formData, this.categoryToEdit.id)
            .then(response => {
              this.$toastr.s(response.message);
              this.categoryModal = false
              this.getCategories();
            })
      }
    },

    deleteCategory() {
      this.$API.faq.deleteCategory(this.toDelete.id)
      .then(response => {
        this.$toastr.s(response.message);
        this.toDelete = null;
        this.getCategories();
        this.$emit('get_cat_id', null);
      })
    }
  },
  mounted() {
    this.getCategories();
    $('body').click(() => {
      this.showMore = null
    })
  }
}
</script>

<style scoped>


</style>