<template>
  <div class="page-shadow">
    <div class="page-with-table">
      <div class="table-header">
        <h3>Contact Us</h3>
      </div>

      <BlockUI message="Loading Messages..." v-if="loading"></BlockUI>

      <template v-else>
        <div class="table-responsive">
          <table class="table wro-table">
            <thead>
            <tr>
              <td>Email</td>
              <td>Subject</td>
              <td>Message</td>
              <td class="text-center">State</td>
              <td class="text-center">Date</td>
              <td class="text-center">Actions</td>
            </tr>
            </thead>
            <tbody>
            <template v-if="contacts && contacts.length > 0">
              <tr v-for="contact in contacts" :key="contact.id">
                <td>{{ contact.email }}</td>
                <td>{{ contact.subject }}</td>
                <td>{{ contact.message  | trim }}</td>
                <td class="text-center">
                  <a>
                    <svg class="envelop" v-if="contact.read == 0">
                      <use xlink:href="@/assets/images/sprite.svg#envelop"></use>
                    </svg>
                    <svg class="envelop read" v-else>
                      <use xlink:href="@/assets/images/sprite.svg#readMessage"></use>
                    </svg>
                  </a>
                </td>
                <td class="text-center">{{ contact.create_dates.created_at_human }} </td>
                <td class="text-center">
                  <router-link :to="'/contact-us/details/' + contact.id">
                    <svg class="eye">
                      <use xlink:href="@/assets/images/sprite.svg#eye"></use>
                    </svg>
                  </router-link>

                  <a href="javascript:void(0)" @click.prevent="toDelete = contact" v-b-modal="'delete-message'">
                    <svg class="icon-12">
                      <use xlink:href="@/assets/images/sprite.svg#trash"></use>
                    </svg>
                  </a>
                </td>
              </tr>
            </template>
            <tr v-else>There are no messages to show</tr>
            </tbody>
          </table>
        </div>

        <Pagination v-if="contacts && contacts.length > 0" @changePage="getAllMessages"></Pagination>
      </template>
    </div>


    <b-modal id="delete-message" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.name" @cancelDelete="toDelete = null" @confirmDelete="deleteMessages"></Delete>
    </b-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Delete from "@/components/Modals/Delete";

export default {
  name: 'ContactUs',
  components: {Pagination, Delete},
  data() {
    return {
      contacts: null,
      loading: true,
      toDelete: null
    }
  },
  mounted() {
    this.getAllMessages(1)
  },
  filters: {
    trim(val) {
      if (val.length > 34) return (val.substr(0, 34)) + ' ....'
      else return val.substr(0, 34)
    },
  },
  methods: {
    getAllMessages(page) {
      this.loading = true;
      this.$API.contacts.getMessages(page)
          .then(response => {
            this.loading = false;
            this.contacts = response;
            // console.log(moment("2021-01-20T06:42:57").format('L'))
            // console.log((response[1].create_dates.created_at))
          })
          .catch(() => {
            this.loading = false;
          });
    },

    deleteMessages() {
      this.loading = true
      this.$API.contacts.deleteMessage(this.toDelete.id)
          .then(response => {
            this.$toastr.s(response);
            this.toDelete = null;
            this.getAllMessages(1)
          })
          .catch(() => {
            this.loading = false;
          });
    }
  }

}
</script>

<style scoped>
a {
  padding: 5px;
}

.read {
  height: 19px;
}

a svg {
  cursor: pointer;
}
</style>