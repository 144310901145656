<template>
  <div class="profile-page">
    <BlockUI message="Loading Profile Data..." v-if="!userData"></BlockUI>

    <template v-else>
      <!-- profile sidebar -->
      <div class="profile-sidebar">
        <!-- user data -->
        <div class="user-data">
          <div class="avatar-wrapper">
            <img :src="userData.image" alt="" class="border-100">
          </div>
          <div>
            <p>{{ userData.name }}</p>
            <!--            <span class="address">
                        <svg>
                          <use xlink:href="@/assets/images/sprite.svg#location"></use>
                        </svg>
                        Alexandria, Egypt
                      </span>-->
          </div>
        </div>

        <!-- Tabs -->
        <ul class="profile-tabs">
          <!-- profile -->
          <li>
            <a href="javascript:void(0)" :class="{'active': selectedTab == 'profile'}"
               @click.prevent="selectedTab = 'profile' ">
            <span>
              <svg class="icon-26">
                <use xlink:href="@/assets/images/sprite.svg#edit-profile"></use>
              </svg>
              Edit profile
            </span>
              <svg class="angle-right">
                <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
              </svg>
            </a>
          </li>

          <!-- change password -->
          <li>
            <a href="javascript:void(0)" :class="{'active': selectedTab == 'change-password'}"
               @click.prevent="selectedTab = 'change-password'">
            <span>
              <svg class="icon-26">
                <use xlink:href="@/assets/images/sprite.svg#change-password"></use>
              </svg>
              Change password
            </span>
              <svg class="angle-right">
                <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
              </svg>
            </a>
          </li>

          <!-- logout -->
          <li>
            <a href="javascript:void(0)" @click.prevent="logout">
            <span>
              <svg class="icon-26">
                <use xlink:href="@/assets/images/sprite.svg#logout"></use>
              </svg>
              Logout
            </span>
              <svg class="angle-right">
                <use xlink:href="@/assets/images/sprite.svg#angle-right"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div class="profile-content">
        <div class="profile-tab" v-if="selectedTab == 'profile'">
          <Profile :userData="userData" @reload="getProfileData"></Profile>
        </div>

        <!-- change password tab -->
        <div class="profile-tab" v-if="selectedTab == 'change-password'">
          <ChangePassword></ChangePassword>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Profile from './components/Profile'
import ChangePassword from "@/pages/profile/components/ChangePassword";

export default {
  name: "ProfileIndex",
  components: {Profile, ChangePassword},
  data() {

    return {
      userData: null,
      selectedTab: 'profile',
    }
  },
  mounted() {
    this.getProfileData();
  },
  methods: {
    getProfileData() {
      this.$API.profile.getProfile()
          .then(response => {
            this.userData = response;
          })
    },

    logout() {
      this.$API.auth.logout()
          .then(response => {
            this.$router.push('/login');
            this.$toastr.s(response)
          })
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>