import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {
    /**
     * Login user
     * @param {object} data
     * @api {POST} api/web/login
     */
    login(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/login',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response);
                    Vuex.commit('setToken', response.data.data.token)
                    Vuex.commit('setUser', response.data.data)
                    localStorage.setItem('token', response.data.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                })
                .catch(error => {
                    reject(error.response.data)
                })
        })
    },

    /**
     * Logout user from dashboard
     * @returns {Promise<unknown>}
     */
    logout() {
        const options = {
            method: 'POST',
            url: API + 'admin/logout',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                    Vuex.commit('removeUser')
                })
                .catch(error => {
                    reject(error.response.data)
                })
        })
    }
}