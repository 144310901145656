<template>
  <div class="support">
    <BlockUI message="Loading ..." v-if="loading"></BlockUI>

    <router-link to="/training-support/add" class="rounded-button add-plan">
      <img src="@/assets/images/add-button.png" alt="Add Plan">
      Add Training
    </router-link>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-for="el in training" :key="el.id">
        <div class="wro-card sm-padding-bottom">
          <div class="wro-card-content sm-padding-bottom">
            <div class="d-flex align-items-center">
              <h3>{{ el.title }}</h3>
              <div class="more" @click.prevent.stop="">
                <a href="javascript:void(0)"
                   @click.prevent="showMore == el.id ? showMore = null: showMore = el.id">
                  <svg class="small dark">
                    <use xlink:href="@/assets/images/sprite.svg#more"></use>
                  </svg>
                </a>
                <ul class="more-actions" :class="{'show': showMore == el.id}">
                  <li>
                    <router-link :to="'/training-support/edit/' + el.id">Edit</router-link>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click.prevent="toDelete = el" v-b-modal="'delete-training'">Delete</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="text-center">
              <img :src="el.image" alt="">
              <p class="card-content">
                {{ el.description }}
              </p>
              <p class="watch-video">
                <a :href="el.hreaf" target="_blank">Click-Here</a>
                <span>For more description</span>
              </p>
            </div>

          </div>
          <div class="edit-button">
            <a :href="el.link">
              <img src="@/assets/images/go.png" alt="Edit">
              Game Page
            </a>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="delete-training" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.title" @cancelDelete="toDelete = null" @confirmDelete="deleteTraining"></Delete>
    </b-modal>
  </div>
</template>

<script>
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  name: "Index",
  components: {Delete},
  data() {
    return {
      showMore: null,
      training: [],
      loading: false,
      toDelete: null
    }
  },
  mounted() {
    this.getTraining();
    $('body').click(() => {
      this.showMore = null
    })
  },
  methods: {
    getTraining() {
      this.loading = true;
      this.$API.trainingSupport.getTraining()
          .then(response => {
            this.training = response;
            this.loading = false
          }).catch(() => {
        this.loading = false
      })
    },

    deleteTraining() {
      this.loading = true
      this.$API.trainingSupport.deleteTraining(this.toDelete.id)
          .then(response => {
            this.$toastr.s(response);
            this.getTraining();
            this.toDelete = null;
            this.loading = false
          }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>