<template>
  <div class="login">
    <div class="row w-100">
      <div class="col-md-6 col-lg-7">
        <div class="login-image">
          <img src="@/assets/images/login.png" alt="Login Image">
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="login-form-wrapper">
          <div class="logo">
            <img src="@/assets/images/logo-blue.png" alt="Logo">
          </div>
          <b-form class="login-form" @submit.prevent="submit">

            <!-- email -->
            <b-form-group
                class="mb-15"
                id="input-group-1"
                label="Email"
                label-for="email">
              <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter Email"
                  required
              ></b-form-input>
              <p class="text-danger error-text" v-if="errors.hasOwnProperty('email')">{{errors['email'][0]}}</p>
            </b-form-group>

            <!-- password -->
            <b-form-group
                id="input-group-2"
                label="Password"
                label-for="password">
              <b-form-input
                  id="password"
                  v-model="form.password"
                  type="password"
                  placeholder="Enter Password"
                  required
              ></b-form-input>
            </b-form-group>

            <!-- keep me logged in  -->
              <b-form-checkbox
                  class="mb-15"
                  id="keepLogged"
                  v-model="keepLogged"
                  name="keepLogged"
                  value="true"
                  unchecked-value="false">
                Keep Me Login
              </b-form-checkbox>

            <!-- agree terms and conditions  -->
            <b-form-checkbox
                id="agreeTerms"
                v-model="agreeTerms"
                name="agreeTerms"
                value="true"
                unchecked-value="false">
              I Agree To The Terms And Conditions
            </b-form-checkbox>

            <!-- submit button -->
            <div class="submit-button">
                <button class="rounded-button" type="submit">
                    Login
                </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {},
      keepLogged: false,
      agreeTerms: false,
      errors: {}
    }
  },
  mounted() {

  },
  methods: {
    submit() {
      this.$API.auth.login(this.form)
      .then(() => {
        this.$router.push('/')
        })
      .catch(error => {
        this.errors = error
        if (typeof error.message == 'string') this.$toastr.e(error.message)
        else this.errors = error.message
      })
    }
  }

}
</script>

<style scoped>

</style>