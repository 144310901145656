import axios from "./axios";
import {API} from '../env'
import Vuex from '../store/index'

export default {

    /**
     * Get all ambassadors
     * @api {GET} admin/originsers
     * @param {number} page
     * @returns {Promise} represents list of ambassadors
     */
    getAmbassadors(page, form) {
        const options = {
            method: 'GET',
            url: API + 'admin/ambassadors',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            params: {'page': page, ...form}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);
                    Vuex.commit('setPages', response.data.data.meta.lastPage)
                    Vuex.commit('setCurrentPage', response.data.data.meta.currentPage)

                }).catch(error => {
                reject(error)
            })
        })
    },


    /**
     * GET ambassador from web api with no pagination
     * @api {GET} web/originsers/
     */
    get_orgnaizer_noPagination() {
        const options = {
            method: 'GET',
            url: API + 'web/orginsers',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    /**
     * Get single ambassador details
     * @api {GET} admin/originsers/:ambassador_id
     * @param {number} ambassadorID
     * @returns {Promise}
     */
    getAmbassadorDetails(ambassadorID) {
        const options = {
            method: 'GET',
            url: API + 'admin/ambassadors/' + ambassadorID,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                reject(error)
            })
        })
    },


    /**
     * Change ambassador status
     * @api {POST} admin/originsers/:status
     * @param status
     * @param ambassadorID
     * @returns {Promise}
     */
            changeAmbassadorStatus(status, ambassadorID) {
        const options = {
            method: 'POST',
            url: API + 'admin/ambassadors/' + status,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {ambassador_id: ambassadorID}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    /**
     * Update link and notes in organiser profile
     * @api {POST} admin/originsers/updatedetails
     * @param {object} data
     * @returns {Promise}
     */
    updateLinkNotes(data) {
        const options = {
            method: 'POST',
            url: API + 'admin/ambassadors/updatedetails',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {...data}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data)
            })
        })
    },

    /**
     * Delete an ambassador
     * @param {number} organiserId
     * @returns {Promise}
     */
    deleteAmbassador(ambassadorId) {
        const options = {
            method: 'Delete',
            url: API + 'admin/ambassadors/' + ambassadorId,
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.message);
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },

    getCountries() {
        const options = {
            method: 'GET',
            url: 'https://server1.01skills.com/backend/api/web/getcountries',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.data);

                }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * Update ambassador email and password
     * @api {POST} admin/originsers/updatedate
     */
    updateCredentials(data) {
        const options = {
            method: 'PUT',
            url: API + 'admin/ambassadors/updatedate',
            headers: {'content-type': 'application/json', Authorization: 'Bearer' + Vuex.getters.token},
            data: {...data}
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
}