<template>
  <div class="competitions">

    <BlockUI message="Loading Competitions ..." v-if="loading"></BlockUI>

    <template v-else>
      <div class="mb-30">
        <router-link to="/competitions/add" class="rounded-button">
          <img src="@/assets/images/add-button.png" alt="Add">
          Add Competition
        </router-link>
      </div>
      <div class="row" v-if="competitions.length > 0">
        <div class="col-md-6 col-lg-4" v-for="competition in competitions" :key="competition.id">
          <div class="wro-card">
            <div class="wro-card-content">
              <div class="d-flex">
                <h3 class="flex-1">{{competition.name}}</h3>
                <div class="more" @click.prevent.stop>
                  <a href="javascript:void(0)"
                     @click.prevent="showMore == competition.id ? showMore = null: showMore = competition.id">
                    <svg class="small dark">
                      <use xlink:href="@/assets/images/sprite.svg#more"></use>
                    </svg>
                  </a>
                  <ul class="more-actions" :class="{'show': showMore == competition.id}">
                    <li>
                      <router-link :to="'/competitions/edit/' + competition.id">Edit</router-link>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click.prevent="toDelete = competition" v-b-modal="'delete-competition'">Delete</a>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="text-center">
                <img :src="competition.image" alt="">
              </div>
              <p>{{competition.short_description}}</p>
            </div>

            <div class="edit-button">
              <router-link :to="'/competitions/edit/' + competition.id">
                <img src="@/assets/images/edit.png" alt="Edit">Edit Competitions
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <h5 class="text-center" v-else>There are no competitions to show</h5>
    </template>

    <b-modal id="delete-competition" hide-footer hide-header ref="my-modal" v-if="toDelete" @hidden="toDelete = null">
      <Delete :to-delete="toDelete.name" @cancelDelete="toDelete = null" @confirmDelete="deleteCompetition"></Delete>
    </b-modal>

  </div>
</template>

<script>
import $ from "jquery";
import Delete from "@/components/Modals/Delete";

export default {
  name: "Index",
  components: {Delete},
  data() {
    return {
      competitions: [],
      loading: true,
      showMore: null,
      toDelete: null
    }
  },
  mounted() {
    this.fetchCompetitions();
    $('body').click(() => {
      this.showMore = null
    })
  },
  methods:{
    fetchCompetitions() {
      this.$API.competitions.getCompetitions()
          .then(response => {
            this.loading = false;
            this.competitions = response
          }).catch(() => {
        this.loading = false
      })
    },

    deleteCompetition(){
      this.loading = true
      this.$API.competitions.deleteCompetition(this.toDelete.id)
          .then(response => {
            this.$toastr.s(response);
            this.toDelete = null;
            this.fetchCompetitions()
          })
          .catch(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>
.rounded-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>