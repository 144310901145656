import { render, staticRenderFns } from "./EditCompetition.vue?vue&type=template&id=645d2cda&scoped=true&"
import script from "./EditCompetition.vue?vue&type=script&lang=js&"
export * from "./EditCompetition.vue?vue&type=script&lang=js&"
import style0 from "./EditCompetition.vue?vue&type=style&index=0&id=645d2cda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645d2cda",
  null
  
)

export default component.exports