import auth from "@/api/auth";
import profile from "@/api/profile";
import organizers from "@/api/organizers";
import ambassadors from "@/api/ambassadors";

import plans from "@/api/plans";
import competitions from "@/api/competitions";
import contacts from "@/api/contacts";
import settings from "@/api/settings";
import faq from "@/api/faq";
import globalEvent from "@/api/event/globalEvents";
import nationalEvent from "@/api/event/nationalEvent";
import trainingSupport from "../api/training-support"
import payment_paypal from "./payment_paypal";


export default {
    auth,
    profile,
    organizers,
    plans,
    competitions,
    contacts,
    settings,
    faq,
    globalEvent,
    nationalEvent,
    trainingSupport,
    payment_paypal,
    ambassadors
}