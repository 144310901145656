import axios from 'axios';
import Vuex from '@/store/index'

const instance = axios.create();
instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response && (error.response.data.message == "Token is Expired" || error.response.data.message == "Token is Invalid")) {
        Vuex.commit('removeUser');
        alert('Session Expired !!')
        window.location.href = '/login'
        delete error.response.data.message
    }
    return Promise.reject(error)
});

export default instance;
