<template>
  <div class="page-shadow organisers">
    <div class="page-with-table">
      <div class="table-header">
        <h3>Mange Payments</h3>
      </div>

      <BlockUI message="Loading Payments..." v-if="!organizersPayments"></BlockUI>

      <!-- filter form -->
      <form class="row filter-form">
        <!-- country -->
        <div class="col-md-4">
          <b-form-group
              id="country"
              label="Country"
              label-for="country">
            <b-form-select v-model="form.orginzer_id">
              <option value="undefined" disabled>Select Country</option>
              <option v-for="country in countries" :value="country.id" :key="country.id">
                {{ country.name }}
              </option>
            </b-form-select>


          </b-form-group>
        </div>

        <!-- filter with status -->
        <div class="col-md-4">
          <b-form-group
              id="status"
              label="Status"
              label-for="status">
            <b-form-select v-model="form.paid">
              <option value="undefined" disabled>Select Status</option>
              <option v-for="el in status" :value="el.key" :key="el.key">{{ el.name }}</option>
            </b-form-select>

          </b-form-group>
        </div>

        <!-- reset form -->
        <div class="col-md-4 align-self-end">
          <div class="form-group">
            <button class="wro-btn primary" @click.prevent="form = {}">Reset</button>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table wro-table">
          <thead>
          <tr>
            <td>Company's name</td>
            <td>Image</td>
            <td>User ID</td>
            <td>Country</td>
            <td>continent</td>
            <td>payment_id</td>
            <td>paid</td>
            <!--            <td>Actions</td>-->
          </tr>
          </thead>
          <tbody>
          <template v-if="organizersPayments && organizersPayments.length > 0">
            <tr v-for="organiser in organizersPayments" :key="organiser.id">
              <td>
                <div class="d-flex align-items-center">
                  {{organiser.company_name}}
<!--                  <div class="inactive-label" v-if="organiser.active == 2"></div>-->
                </div>
              </td>
              <td>
                <div class="logo-wrapper">
                  <img v-if="organiser.logo" :src="organiser.logo" alt="logo">
                </div>
              </td>
              <td>{{ organiser.user_id }}</td>
              <td>{{ organiser.country }}</td>
              <td>{{ organiser.continent }}</td>
              <td>{{ organiser.payment_id }}</td>
              <td>
                  <button class="success wro-btn" v-if="organiser.paid == 1">
                    Paid
                  </button>
                  <button class="danger wro-btn" v-else>
                    Not Paid
                  </button>
              </td>
            </tr>
          </template>
          <tr v-else class="text-center">
            <td colspan="6">There is no data to show</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Pagination v-if="organizersPayments && organizersPayments.length > 0" @changePage="getAllOrganizersPayments"></Pagination>

    </div>

  </div>
</template>

<script>
import $ from "jquery";
import Pagination from "../../components/Pagination";

export default {
  name: "Index",
  components: {Pagination},
  data() {
    return {
      organizersPayments: null,
      showMore: null,
      countries: [],
      status: [
        {
          key: 0,
          name: 'Not Paid'
        }, {
          key: 1,
          name: 'Paid'
        }],
      form: {},
      editCredentials: null,
      credentialsModal: false,
      toDelete: null
    }
  },
  watch: {
    'form': {
      deep: true,
      handler: 'filter'
    }
  },
  mounted() {
    this.getAllOrganizersPayments(1);
    this.$API.organizers.get_orgnaizer_noPagination()
        .then(response => {
          this.countries = response.data
        });

    $('body').click(() => {
      this.showMore = null
    })
  },
  methods: {
    filter() {
      this.getAllOrganizersPayments()
    },

    /**
     * Method invoke to get all organizers payments.
     */
    getAllOrganizersPayments(page) {
      this.organizersPayments = null

      this.$API.payment_paypal.getOrganizerPayment(page, this.form)
          .then(response => {
            this.organizersPayments = [];
            response.map(el => {
              this.organizersPayments.push({
                payment_id: el.payment_id,
                paid: el.paid,
                company_name: (el.orginzer ? el.orginzer.company_name : 'no data'),
                user_id: el.user_id,
                phone:(el.orginzer ?   el.orginzer.phone : 'no data') ,
                continent:(el.orginzer ? el.orginzer.continent : 'no data') ,
                country:(el.orginzer ?  el.orginzer.country : 'no data') ,
                logo:(el.orginzer ? el.orginzer.logo : 'no data')
              });
            });
          })
    },
  }
}
</script>

<style scoped>
.inactive-label {
  margin-left: 15px;
}
table button {
  width: 100%;
}
</style>